import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import starImg from "../assets/images/star-icon.png";
import Arrow3 from "../assets/images/arrow/arrow3.png";
import Arrow4 from "../assets/images/arrow/arrow4.png";
import MobAr1 from "../assets/images/arrow/mob-ar1.png";
import MobAr2 from "../assets/images/arrow/mob-ar2.png";
const Testimonial = () => {
  const settings = {
    infinite: true,
    className: "first-row",
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    rtl: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const setting2 = {
    infinite: true,
    className: "center",
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    rtl: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="mb-12">
        <div className="max-w-[840px] m-auto px-5 relative testimonil">
          <div className="flex md:hidden">
            <img src={MobAr2} alt="Arrow" className="w-48" />
          </div>
          {/* <div className="hidden absolute -left-20 md:-left-10 lg:-left-20 xl:-left-28 md:flex justify-center items-center flex-col top-28 md:top-16 lg:top-24">
            <span
              className="absolute -left-2 caveat text-lg -top-5"
              style={{ transform: "rotate(-32deg)" }}
            >
              Join our Keto
              <br /> Community
            </span>
            <img src={Arrow3} alt="Arrow" className="w-32 md:w-28 lg:w-32" />
          </div> */}
          <h2 className="mb-4 text-left sm:text-center font-extrabold text-4xl  leading-10 md:text-[64px] sm:text-5xl 2xl:text-7xl sm:leading-[65px] md:leading-[80px] 2xl:leading-[98px]">
            Trusted by Thousands Worldwide!
          </h2>
          <p className="mb-10 font-normal text-base sm:text-lg 2xl:text-2xl md:max-w-[780px] lg:max-w-[840px] m-auto text-left sm:text-center">
            Join the <strong>KetoBalanced</strong> community and{" "}
            <strong>transform your life</strong> with the power of keto-one meal
            at a time!
          </p>
          <div className="flex md:hidden justify-end -mt-10 mb-12">
            <img src={MobAr1} alt="Arrow" className="w-28" />
          </div>

          {/* <div className="hidden absolute -right-14  md:-right-24 lg:-right-28 md:flex justify-center items-center flex-col bottom-5 2xl:bottom-12">
            <span className="absolute -left-2 caveat text-lg -top-12 -rotate-2 caveat">
              Intelligent way
              <br /> to keto
            </span>
            <img src={Arrow4} alt="Arrow" className="w-40" />
          </div> */}
        </div>

        <Slider {...settings}>
          <div>
            <div className="bg-white rounded-3xl p-4 md:p-7 mx-2 md:mx-4">
              <img src={starImg} alt="star icon" />
              <p className="text-xs sm:text-base md:text-lg mt-5 mb-10 sm:mb-14">
                Never imagined keto could do such won-ders tio my health. I've
                been following for last a few months and lost 32 lbs for now and
                trust mecutting carbs is bliss to me. Love!
              </p>

              <div className="flex justify-between items-center">
                <p className="text-[#417421] text-xs  md:text-base">
                  App Store Review
                </p>
                <p className="text-xs  md:text-base">Rentia Steyn</p>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-white rounded-3xl p-4 md:p-7 mx-2 md:mx-4">
              <img src={starImg} alt="star icon" />
              <p className="text-xs sm:text-base md:text-lg mt-5 mb-10 sm:mb-14">
                Brilliant plan! | was first sceptical about it but once started,
                all my doubt has gone away now and the results? OMG! It's
                unbelievable! All thanks to this keto meal plan!
              </p>

              <div className="flex justify-between items-center">
                <p className="text-[#417421] text-xs  md:text-base">
                  App Store Review
                </p>
                <p className="text-xs  md:text-base">Carmen Harris</p>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-white rounded-3xl p-4 md:p-7 mx-2 md:mx-4">
              <img src={starImg} alt="star icon" />
              <p className="text-xs sm:text-base md:text-lg mt-5 mb-10 sm:mb-14">
                I couldn't ever imagine that | could ever restrain my food
                cravings but this keto never lets me feel deprived of anything |
                love to eat. Such an awesome and amaz- ingly designed meal plan
                it is!!
              </p>

              <div className="flex justify-between items-center">
                <p className="text-[#417421] text-xs  md:text-base">
                  App Store Review
                </p>
                <p className="text-xs  md:text-base">Stephanie Ortego</p>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-white rounded-3xl p-4 md:p-7 mx-2 md:mx-4">
              <img src={starImg} alt="star icon" />
              <p className="text-xs sm:text-base md:text-lg mt-5 mb-10 sm:mb-14">
                This high fat-low carb thing really worked forme and | got an
                extraordinary result out of this. Plus my blood sugar and other
                related ailments are under control now. Wow!
              </p>

              <div className="flex justify-between items-center">
                <p className="text-[#417421] text-xs  md:text-base">
                  App Store Review
                </p>
                <p className="text-xs  md:text-base">Hamsa Oueity Amin</p>
              </div>
            </div>
          </div>
        </Slider>
      </div>

      {/* <!----------------2nd-testimonial----------------> */}
      <div className="my-12">
        <Slider {...setting2}>
          <div>
            <div className="bg-white rounded-3xl p-4 md:p-7 mx-2 md:mx-4">
              <img src={starImg} alt="star icon" />
              <p className="text-xs sm:text-base md:text-lg mt-5 mb-10 sm:mb-14">
                Been doing keto for last a couple of months and it takes care
                all of my snack and dessert craving as well as helps me shed
                pounds in such a short period of time. Amazing!
              </p>

              <div className="flex justify-between items-center">
                <p className="text-[#417421] text-xs  md:text-base">
                  App Store Review
                </p>
                <p className="text-xs  md:text-base">Teresa Coleman</p>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-white rounded-3xl p-4 md:p-7 mx-2 md:mx-4">
              <img src={starImg} alt="star icon" />
              <p className="text-xs sm:text-base md:text-lg mt-5 mb-10 sm:mb-14">
                Nothing could beat this high fat and low carb combo when you're
                sincerely looking for weight loss. I'm saying this because |
                have been doing this.
              </p>

              <div className="flex justify-between items-center">
                <p className="text-[#417421] text-xs  md:text-base">
                  App Store Review
                </p>
                <p className="text-xs  md:text-base">Allison Gabrielle</p>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-white rounded-3xl p-4 md:p-7 mx-2 md:mx-4">
              <img src={starImg} alt="star icon" />
              <p className="text-xs sm:text-base md:text-lg mt-5 mb-10 sm:mb-14">
                This low-carb, high-fat approach truly worked wonders for me! I
                achieved incredible results, and my blood sugar and related
                health issues are now well managed. Amazing!
              </p>

              <div className="flex justify-between items-center">
                <p className="text-[#417421] text-xs  md:text-base">
                  App Store Review
                </p>
                <p className="text-xs  md:text-base">Sarah W.</p>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-white rounded-3xl p-4 md:p-7 mx-2 md:mx-4">
              <img src={starImg} alt="star icon" />
              <p className="text-xs sm:text-base md:text-lg mt-5 mb-10 sm:mb-14">
                Going high-fat and low-carb really paid off for me! I got
                outstanding results, and my blood sugar and other health
                concerns are now in check. Incredible!
              </p>

              <div className="flex justify-between items-center">
                <p className="text-[#417421] text-xs  md:text-base">
                  App Store Review
                </p>
                <p className="text-xs  md:text-base">Stephanie Ortego</p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default Testimonial;
