import React from 'react'
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
      <div className='w-full bg-ftrgreenColor py-5 sm:px-12 lg:px-24  px-5 flex justify-between text-white md:flex-row flex-col text-center md:text-start'>
        <div className=''><p className='font-semibold'>Copyright © 2025 Ketobalanced.com</p></div>
        <div className='flex gap-2 sm:gap-3  xl:gap-14 font-semibold justify-center'>   
          <Link to="/terms" className="text-white hover:text-gray-300 hover:underline text-xs sm:text-base">Terms & Condition</Link>
          <Link to="/privacy" className="text-white hover:text-gray-300 hover:underline text-xs sm:text-base">Privacy Policy</Link>
          <Link to="/cookies" className="text-white hover:text-gray-300 hover:underline text-xs sm:text-base">Cookie Policy</Link>
          <Link to="/trusted-sources" className="text-white hover:text-gray-300 hover:underline text-xs sm:text-base">Trusted Sources</Link>
          </div>
      </div>
    </>
  )
}

export default Footer
