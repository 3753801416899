import { useEffect } from "react";

import Header from "../component/Header";
import Footer from "../component/Footer";
import TopFooter from "../component/TopFooter";
import NewsLetter from "../component/NewsLetter";
import Serachlogo from "../assets/images/search.png";
import Rightarrow from "../assets/images/rightarrow.png";
import { Link } from "react-router-dom";
const General = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bg-custombgColor">
      <Header />
      <div className="px-5 md:px-0 md:mx-24 sm:mx-12">
        <div className="md:pb-12 md:pt-12 pt-12 pb-5 flex flex-col md:flex-row justify-between border-btntextColor">
          <div className="bordctex flex gap-1">
            <Link to="/help-center">Help center</Link>{" "}
            <img
              src={Rightarrow}
              alt="rightarrow"
              className=" h-3 mx-1 my-auto "
            />
            <Link to="/general">General Questions</Link>
          </div>
          <div className="flex flex-row items-center space-x-4 max-w-[500px] ">
            <div className="relative max-w-[350px] ">
              <input
                type="search"
                id="searchteat"
                placeholder="Search your keyword hear..."
                className="pl-10 pr-4 py-2 border border-headingColor rounded-[40px] focus:outline-none focus:ring-2 focus:ring-slate-200 placeholder:text-base placeholder:font-normal placeholder:text-[#bebbbb] h-12 w-full"
              />
            </div>
            <button className="bg-btntextColor text-white rounded-[40px] focus:outline-none text-lg font-bold w-12 h-12">
              <img
                src={Serachlogo}
                alt="search"
                className="w-6 h-6 text-center m-auto"
              />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5 mb-20">
          {/* Main Content */}
          <div className="col-span-12 md:col-span-9 order-last md:order-first">
            <h1 className="text-5xl sm:text-5xl md:text-6xl lg:text-[64px] mb-7 font-extrabold">
              {" "}
              General Questions
            </h1>
            <h3 className="font-bold text-[32px] mb-3" id="about-ketobalanced">
              About KetoBalanced
            </h3>
            <p className="text-base sm:text-lg 2xl:text-2xl lead" style={{lineHeight:"1.5"}}>
              Our expert nutritionists have come together to create dieting
              schedules for all our consumers. The app has been tailored to
              create meal plans that take into account all of the client's
              particular preferences and wishes. You will be given a cooking
              list (five recipes per day) and a shopping list to help you stick
              to a keto diet. When you click on the recipes, you will get all of
              the nutritional information as well as directions on how to
              prepare the meals. Every 14 days, a new food plan will be produced
              for you. Our recipe database is always being updated. After making
              your order, you will receive a digital keto diet meal plan.<br/><br/> You
              can access your meal plan online from your computer or phone
              (through an app). Remember, we DO NOT send physical dieting plans.
              However, you can always print them out! There are also calorie,
              step, and water intake monitors in the app You will also receive
              an email with our beginner's guide and beneficial recommendations,
              allowing you to conveniently identify all of the necessary general
              and additional information about the keto diet. You can also buy
              our workout regimen, a keto dessert book, and other services.
              Remember, just dieting will not fully enhance your weight
              shredding unless you combine it with exercising.
            </p>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="what-ketobalanced"
            >
              What is KetoBalanced?
            </h3>
            <div>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Is KetoBalanced suitable for beginners?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                Absolutely! Whether you're new to keto or have been following a
                keto lifestyle, KetoBalanced offers personalized plans to meet
                you where you are on your journey.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                How does KetoBalanced personalize my diet plan?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                We use your dietary preferences, lifestyle, and goals to create
                a customized keto diet plan. This ensures your plan is both
                enjoyable and effective.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                What sets KetoBalanced apart from free keto resources?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                {" "}
                KetoBalanced provides a personalized approach, with meal plans
                crafted by nutrition experts, along with comprehensive support
                and tracking through our app.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                {" "}
                Can KetoBalanced accommodate my vegetarian/vegan keto diet?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}> 
                Yes, we offer vegetarian and vegan options to ensure your keto
                journey aligns with your dietary choices.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                Does KetoBalanced provide ongoing support?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                Yes, our team is committed to supporting you throughout your
                journey, with resources, advice, and adjustments to your plan as
                needed.
              </p>
            </div>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="getting-started"
            >
              Getting Started
            </h3>
            <div>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                What information do I need to provide to start?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                You'll share some basic health information, dietary preferences,
                and your specific goals to help us tailor your plan.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                How quickly can I start my plan after signing up?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Your personalized meal plan will be available within 24 hours of
                completing your profile.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                Is there an initial consultation or assessment?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                Our sign-up process includes a detailed questionnaire that acts
                as an initial assessment to understand your needs.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                Can I use KetoBalanced if I have a medical condition?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                We recommend consulting with a healthcare provider before
                starting any new diet plan, especially if you have a medical
                condition.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                What tools do I need to follow the KetoBalanced plan?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                Access to our app and a willingness to embrace your keto journey
                are all you need to get started!
              </p>
            </div>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="meal-plans"
            >
              Meal Plans
            </h3>
            <div>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                How frequently do the meal plans change?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                Meal plans are updated weekly to provide variety and adapt to
                your progress and feedback.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                Can I repeat meals I particularly enjoy?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                Yes, you can customize your meal rotation to include your
                favorites more often.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Are the recipes difficult to follow?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                Our recipes are designed to be simple and straightforward,
                making it easy for anyone to follow, regardless of cooking
                experience.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                What if I have allergies to certain ingredients?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                {" "}
                Your meal plan can be customized to avoid allergens and
                substitute ingredients as needed.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Does KetoBalanced accommodate intermittent fasting schedules?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                Yes, we can tailor your meal plan to fit an intermittent fasting
                schedule if you choose.
              </p>
            </div>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="app-features"
            >
              App Features
            </h3>
            <div>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                Is the KetoBalanced app compatible with all devices?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                The KetoBalanced app is available on both iOS and Android
                devices.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                Can I track my macros in the app?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Yes, our app includes a macro tracker to help you stay on top of
                your daily intake.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                Does the app offer workout suggestions or plans?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                Yes, the app provides workout videos and suggestions tailored to
                your fitness level and goals.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                How does the ingredient swap feature work?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                If you dislike or can't eat a certain ingredient, our app lets
                you swap it out for an approved alternative with similar
                nutritional value.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3"  style={{lineHeight:"1.5"}}>
                Can I share my progress with friends or family through the app?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl"  style={{lineHeight:"1.5"}}>
                Yes, there are social sharing features that allow you to share
                your journey and achievements with loved ones.
              </p>
            </div>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="support"
            >
              Support
            </h3>
            <div>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                How can I contact customer support if I have a problem?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Our support team is available via email at
                support@ketobalanced.com, ready to assist you with any issues or
                questions.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Does KetoBalanced offer community support or forums?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Yes, we provide access to a community forum where you can
                connect with other members, share experiences, and receive peer
                support.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                {" "}
                Can I get one-on-one nutritional counseling?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                KetoBalanced offers the option for one-on-one sessions with our
                nutritionists for personalized guidance and support.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                What kind of support materials does KetoBalanced provide?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                We offer a range of support materials, including guides, how-to
                articles, and video tutorials to help you navigate your keto
                journey.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                How quickly does the support team respond to inquiries?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                We strive to respond to all inquiries within 24 hours.
              </p>
            </div>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="adjusting-plan"
            >
              Adjusting Your Plan
            </h3>
            <div>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                How often can I adjust my meal plan?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                You can request adjustments to your meal plan anytime through
                the app, based on your preferences or dietary changes.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                What if I want to switch from a standard keto plan to a
                vegetarian keto plan?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Changing your diet preferences is easy and can be done directly
                in the app or by contacting our support team.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Can I adjust my calorie intake goal?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Yes, you can update your calorie goals in the app, and your meal
                plan will adjust accordingly.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                What happens if I don't like the meals selected for me?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Our app allows you to swap meals you don't like with
                alternatives, ensuring you always enjoy your meals.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Is it possible to pause my meal plan if I go on vacation?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Yes, you can pause your plan and resume it when you're ready
                through the app settings.
              </p>
            </div>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="success-tracking"
            >
              {" "}
              Success Tracking
            </h3>
            <div>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                How does KetoBalanced help me track my weight loss progress?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Our app includes a progress tracker where you can log your
                weight, measurements, and photos over time.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Can I track my water intake and steps in the app?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Yes, the app features water intake and step trackers to help you
                meet your daily hydration and activity goals.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Does KetoBalanced offer progress reports?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                We provide weekly progress reports through the app, summarizing
                your achievements and areas for improvement.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                What if I hit a weight loss plateau?{" "}
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Our support team and resources are here to help you through
                plateaus with tips, adjustments to your plan, and motivation.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Can I set and track specific fitness goals in the app?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Yes, you can set personal fitness goals, and our app will help
                you track your progress towards achieving them.
              </p>
            </div>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="billing-payment"
            >
              {" "}
              Billing and Payment
            </h3>
            <div>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                What payment methods does KetoBalanced accept?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                We accept major credit cards and PayPal for your convenience.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                How can I view my billing history or receipts?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Your billing history and receipts are accessible in the app
                under your account settings.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Is there a trial period for KetoBalanced?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                We occasionally offer trial periods or special promotions. Check
                our website or app for current offers.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                What happens if my payment fails?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                If a payment fails, you'll receive a notification with steps to
                update your payment information and resolve the issue.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Can I change my subscription plan?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Yes, you can upgrade or change your subscription plan directly
                through the app or by contacting customer support.
              </p>
            </div>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="cancellation-policy"
            >
              {" "}
              Cancellation Policy
            </h3>
            <div>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                How do I cancel my KetoBalanced subscription?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                You can cancel your subscription anytime through the app
                settings or by contacting our support team for assistance.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Will I receive a refund if I cancel my subscription?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Our refund policy is outlined on our website. Generally, refunds
                are considered on a case-by-case basis for unused portions of
                your subscription.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                What happens to my data if I cancel?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                You can choose to delete your account and data permanently or
                keep your account inactive in case you decide to return later.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Can I restart my subscription after cancellation?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Yes, restarting your subscription is easy. Simply log in to the
                app and choose the plan you wish to reactivate.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Are there any fees for canceling my subscription?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                No, there are no cancellation fees. You can cancel your
                subscription at any time without penalty.
              </p>
            </div>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="results"
            >
              {" "}
              Results
            </h3>
            <div>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                How long before I see results with KetoBalanced?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Results vary, but many users begin to see changes within the
                first few weeks. Consistency is key to achieving your goals.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Will KetoBalanced work for me if other diets haven't?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Many users find success with KetoBalanced thanks to its
                personalized approach and comprehensive support system.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                What if I’m not satisfied with my results?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                We’re committed to your satisfaction and success. Contact us for
                personalized tips and adjustments to your plan.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                How can I maximize my results with KetoBalanced?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Following your meal plan closely, staying active, and using all
                the resources and support available through our app will help
                you maximize your results.
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3" style={{lineHeight:"1.5"}}>
                Does KetoBalanced guarantee weight loss?
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                While individual results vary, KetoBalanced is designed to
                promote weight loss by tailoring your diet to your personal
                needs and preferences.
              </p>
            </div>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="login-problem"
            >
              Login Problem
            </h3>
            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              If you are unable to access your account, try out the following
              suggestions:{" "}
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              {" "}
              After opening the app, always select Log in rather than Create an
              account. If you no longer see the login window, please uninstall
              and reinstall the software.{" "}
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              {" "}
              Remember to use the email address you used to sign up (if you have
              more than one).{" "}
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              {" "}
              If your app login credentials appears to be incorrect, please
              click the Forgot your password? link on the login page. Your email
              address will receive an email with more instructions. Don't forget
              to check your Spam bin in case our letter ends up there!{" "}
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              Try Updating the OS on your phone.{" "}
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              If you followed these procedures but were still unable to access
              the app, there could be another problem. For example, you could
              have made an error in your email address or password, or there
              could be a technical glitch. In this instance, we respectfully
              request that you contact our support team via the Contact us
              button located at the bottom of this page. And that's all!
            </p>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="glitch-downloads"
            >
              A Glitch in Downloads
            </h3>
            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              The KetoBalanced app is available for both Apple and Android
              devices.
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              {" "}
              When checking in to the app, ensure that there are no spaces
              before or after your email address. Passwords are also
              case-sensitive, so please keep uppercase and lowercase characters
              and symbols in mind.
            </p>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="how-use-from-desktop"
            >
              How To Use From Desktop
            </h3>
            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              You can see your meal plan via your browser by clicking on{" "}
              <a href="https://user.ketobalanced.com">Access My Plan</a>. Just
              type in your email address and password to obtain the plan,
              workouts, and your profile. From this page, you will be able to
              print your meal plan and grocery list, as well as manage your
              membership. If you are unable to log in, select the Forgot
              password? option. Make sure you use the correct email address that
              you used to register for KetoBalanced.
            </p>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="not-receiving-anything-after-purchase"
            >
              Not Receiving Anything After Purchase
            </h3>
            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              If you haven't gotten an email from us after purchasing your
              KetoBalanced meal plan, please check your Spam and Promotion
              folders. The letters could have landed up there accidentally!{" "}
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              Otherwise, just download the app and log in with your credentials.{" "}
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              {" "}
              The KetoBalanced app is available for download from the Apple App
              Store or the Google Play Store.{" "}
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              {" "}
              Please tap Log in rather than Create an account after downloading
              the app. Enter your email address from the signup procedure as
              well as your password.{" "}
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              If the login information appears to be incorrect, please click the
              Forgot your password? button or contact us using the button at the
              bottom of this article, and we will assist you as soon as
              possible.
            </p>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="forgot-passcode"
            >
              Forgot Your Passcode?
            </h3>
            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              If your KetoBalanced app login information appears to be incorrect
              and you are unable to log in, please utilize the Forgot your
              password? option on the login page of your app. Your address will
              be sent an email with more instructions. Check your spam folder in
              case our letter ended it there!
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              {" "}
              You will be able to log in to your account again once you have
              changed your password according to the instructions.
            </p>

            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              If you didn't get the password reset link in your email, or if you
              have any other questions, please contact us using the button at
              the bottom of this page, and our team will respond as soon as
              possible.
            </p>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="keto-support-community"
            >
              Keto Support Community
            </h3>
            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
              Support is crucial when you start a diet, whether it's friends,
              family or colleagues. However, you can also join the KetoBalanced
              Facebook community to read inspiring success stories, get
              practical tips, contribute your own opinions, and celebrate
              accomplishments together. It will be really healthy to know others
              are going on the same journey as you!
            </p>
            <h3
              className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 "
              id="support-team-contact-info"
            >
              Support Team Contact Info
            </h3>
            <div>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                Contact us by using the Contact us form located under each Help
                Center article (including this one!) or by utilizing the live
                chat feature in the Help Center or the KetoBalanced app.
              </p>

              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                {" "}
                You can also reach us at +1 (518) 972-0028. Open from 9 a.m. to
                9 p.m. EST.
              </p>

              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                {" "}
                Keep in mind that if you contact support using an email address
                other than the one you used to create your KetoBalanced account,
                you are required to verify your account. In order to do so, you
                will be asked questions about your payment. Please have the
                subsequent information ready:
              </p>
              <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl ">
                <li className="mb-1">
                  {" "}
                  Whatever you used to pay (Credit Card or Paypal)
                </li>
                <li className="mb-1">
                  For PayPal, please include your PayPal email address as well
                  as a screenshot of the receipt.
                </li>
                <li className="mb-1">
                  {" "}
                  For credit cards, please provide the full name of the account
                  holder as well as the last four digits of the credit card.
                </li>
                <li className="mb-1">A screenshot or a charge receipt.</li>
                <li className="mb-1">
                  The email address with which you registered.
                </li>
              </ul>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                When your question pertains to your payment or other sensitive
                data, we will ask for this details.
              </p>

              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                {" "}
                If you wrote to our support team a few days ago and haven't
                heard back, make sure you're receiving our emails:
              </p>
              <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl ">
                <li className="mb-1">
                  {" "}
                  Examine whether the email was archived, deleted, or tagged as
                  spam.
                </li>
                <li className="mb-1">
                  Examine your email filters to see if the email was filtered or
                  blocked.
                </li>
                <li className="mb-1">
                  Examine your email forwarding settings.
                </li>
                <li className="mb-1">Termination of Account</li>
              </ul>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                <b>Note;</b>
              </p>
              <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl ">
                <li className="mb-1">
                  Your account will be permanently deleted. If you delete your
                  account, we will not be able to retrieve your data if you
                  change your mind.
                </li>
                <li className="mb-1">
                  If you purchased the software through Google Play or the
                  software Store, canceling your account will not instantly
                  cancel your subscription. Please remember to cancel your
                  subscription as well.
                </li>
                <li className="mb-1">
                  The deletion of data is irreversible. It does not start the
                  refund process. If you want to receive a refund, please
                  contact our customer service before doing the data erasure
                  actions outlined above.
                </li>
                <li className="mb-1">
                  If you purchased actual things from us and would like to
                  return them, please contact us before your account is deleted.
                  The support team will make certain that any issues you may
                  have are remedied prior to deletion.
                </li>
              </ul>
              <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>
                <b>Now, To Delete;</b>
              </p>
              <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl ">
                <li className="mb-1">Run the KetoBalanced app.</li>
                <li className="mb-1">
                  From the bottom menu, go to the Profile area.
                </li>
                <li className="mb-1">View your profile &gt; My information.</li>
                <li className="mb-1"> Click Delete account at the bottom.</li>
                <li className="mb-1">
                  More details regarding the deletion will be displayed. Read to
                  ensure that this is what you want to accomplish, then submit.
                </li>
                <li className="mb-1">
                  You may remove your account after entering a reason for
                  deletion.
                </li>
              </ul>
            </div>
          </div>
          {/* Sidebar */}
          <div className="col-span-12 md:col-span-3 leftboox md:sticky top-24  min-h-fit md:h-[475px] rounded-3xl">
            <div className="px-5 py-4 md:px-6 md:py-8">
              <ol className="litex space-y-2 pl-4">
                <li className="mb-1">
                  <a href="#about-ketobalanced" className=" hover:underline">
                    About KetoBalanced
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#what-ketobalanced" className=" hover:underline">
                    What is KetoBalanced?
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#getting-started" className=" hover:underline">
                    {" "}
                    Getting Started
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#meal-plans" className=" hover:underline">
                    {" "}
                    Meal Plans
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#app-features" className=" hover:underline">
                    {" "}
                    App Features
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#support" className=" hover:underline">
                    {" "}
                    Support
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#adjusting-plan" className=" hover:underline">
                    {" "}
                    Adjusting Your Plan
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#success-tracking" className=" hover:underline">
                    Success Tracking
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#billing-payment" className=" hover:underline">
                    Billing and Payment
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#cancellation-policy" className=" hover:underline">
                    Cancellation Policy
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#results" className=" hover:underline">
                    Results
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#login-problem" className=" hover:underline">
                    Login Problem
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#glitch-downloads" className=" hover:underline">
                    A Glitch in Downloads
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#how-use-from-desktop" className=" hover:underline">
                    How To Use From Desktop
                  </a>
                </li>
                <li className="mb-1">
                  <a
                    href="#not-receiving-anything-after-purchase"
                    className=" hover:underline"
                  >
                    Not Receiving Anything After Purchase
                  </a>
                </li>
                <li className="mb-1">
                  <a href="#forgot-passcode" className=" hover:underline">
                    Forgot Your Passcode?
                  </a>
                </li>
                <li className="mb-1">
                  <a
                    href="#keto-support-community"
                    className=" hover:underline"
                  >
                    Keto Support Community
                  </a>
                </li>
                <li className="mb-1">
                  <a
                    href="#support-team-contact-info"
                    className=" hover:underline"
                  >
                    Support Team Contact Info
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <TopFooter />
      <Footer />
    </div>
  );
};

export default General;
