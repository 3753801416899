import React from 'react'
import DietJourney from './DietJourney'
import WeightlossGoal from './WeightlossGoal'
import NewsLetter from './NewsLetter'
import TopFooter from './TopFooter'
import ThousandsTransformed from './ThousandsTransformed'
const BottomWrap = () => {
  return (
    <>
      <div className='w-full bg-custombgColor'>
        <DietJourney />
        <ThousandsTransformed />
        <WeightlossGoal />
        <NewsLetter />
        <TopFooter />
      </div>
    </>
  )
}

export default BottomWrap
