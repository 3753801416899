import React from 'react'

import Header from '../component/Header'
import Footer from '../component/Footer'
import TopFooter from '../component/TopFooter'
import NewsLetter from '../component/NewsLetter'
import Serachlogo from '../assets/images/search.png'
import rightarrow from '../assets/images/rightarrow.png'
import { Link } from 'react-router-dom'
const Paymentinfo = () => {
    return (
        <div className='bg-custombgColor'>
            <Header />
            <div className='px-5 md:px-0 md:mx-24 sm:mx-12'>
                <div className='md:pb-12 md:pt-12 pt-12 pb-5 flex flex-col md:flex-row justify-between border-btntextColor'>
                    <div className='max-w-96'>
                        <div className='bordctex flex gap-1'>
                            <Link to="/help-center" >Help center</Link> <img src={rightarrow} alt='rightarrow' className=' h-3 mx-1 my-auto ' />
                            <Link to="/paymentinfo" >Payment Information</Link>
                        </div>
                    </div>
                    <div className="flex flex-row items-center space-x-4 max-w-[500px] ">
                        <div className="relative max-w-[350px] ">
                            <input
                                type="search"
                                id="searchteat"
                                placeholder="Search your keyword hear..."
                                className="pl-10 pr-4 py-2 border border-headingColor rounded-[40px] focus:outline-none focus:ring-2 focus:ring-slate-200 placeholder:text-base placeholder:font-normal placeholder:text-[#bebbbb] h-12 w-full"
                            />
                        </div>
                        <button className="bg-btntextColor text-white rounded-[40px] focus:outline-none text-lg font-bold w-12 h-12">
                            <img src={Serachlogo} alt='search' className='w-6 h-6 text-center m-auto' />
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-5 mb-20">
                    {/* Main Content */}
                    <div className="col-span-12 md:col-span-9 order-last md:order-first">
                        <h1 className="text-5xl sm:text-5xl md:text-6xl lg:text-[64px] mb-7 font-extrabold">Payment Information</h1>
                        <h3 className="font-bold text-[32px]" id="request-refund">Our Refund Policy</h3>
                        <div>
                            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>Please get in touch with our customer service within 14 days of your purchase if you want a refund and give specific details about the problem. Kindly be willing to show us some sort of visual evidence that the product was defective.</p>
                            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>If you want to ask for a refund, fill out the contact form below or send an email to support@ketobalanced.com. Kindly make sure to include your email address from when you signed up as well as evidence of payment (a payment receipt or a snapshot of your bank account).</p>
                            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>All credits are applied to the original payment method for all returns.</p>
                            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}> Visit our Terms &amp; Conditions page at https://ketobalanced.com/general-conditions.html to learn more about this policy.</p>
                        </div>
                        <h3 className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 " id="membership-plan">Membership Plan Prices</h3>
                        <div>
                            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>We provide three different subscription packages. These are of different durations: three months, six months, and twelve months.</p>
                            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}> Due to our seasonal and individualized discounts, meal plan costs might vary. Get the best deal right now by taking our 60-second questionnaire! </p>
                        </div>
                        <h3 className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 " id="payment-been-declined">Why has my Payment been Declined?</h3>
                        <div>
                            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>You can pay via Paypal and most major credit card providers.</p>
                            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>  Check your card information to be sure it hasn't expired if your payment was denied. To determine whether a purchase is feasible, don't forget to check your account limit and balance. Finally, confirm that foreign and online purchases are permitted with your credit card.</p>
                            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>   Please get in touch with your bank or credit card provider if you are unable to complete your purchase. You can also use a different card or payment method as an alternative.</p>
                            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.5"}}>  It can also be a pre authorization if the purchase wasn't confirmed but you see a pending transaction in your bank account. Depending on your bank, preauthorization often expires within 7 days.</p>
                        </div>
                    </div>
                    {/* Sidebar */}
                    <div className="col-span-12 md:col-span-3 leftboox md:sticky top-24  min-h-fit md:h-[475px] rounded-3xl">
                        <div className="px-5 py-4 md:px-6 md:py-8">
                            <ol className='litex space-y-2 pl-4'>
                                <li><a href="#request-refund" className=" hover:underline">Request a Refund</a></li>
                                <li><a href="#membership-plan" className=" hover:underline">Membership Plan Prices</a></li>
                                <li><a href="#payment-been-declined" className=" hover:underline">Why has my Payment been Declined?</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <NewsLetter />
            <TopFooter />
            <Footer />
        </div>
    )
}
export default Paymentinfo
