import React from "react";
import Burgur from "../assets/images/burgur.png";
import Leaf4 from "../assets/images/arrow/leaf4.png";
import Icn3 from "../../src/assets/images/arrow/icn3.png";
import Icn2 from "../../src/assets/images/avc-img-3.png";

const UnderstandingKeto = () => {
  return (
    <>
      <div className="w-full bg-btngreenColor px-5 py-7 sm:p-14 md:p-24">
        <div className="max-w-[850px] 2xl:max-w-[955px] m-auto text-left sm:text-center">
          <h2 className="relative  sm:text-[60px] font-extrabold text-headingColor sm:leading-tight mb-3 text-4xl leading-10 md:text-[64px] sm:text-5xl 2xl:text-7xl md:leading-[80px] 2xl:leading-[98px]">
            <img
              src={Leaf4}
              className="w-5 sm:w-[50px] absolute -right-5 sm:-right-6 md:-right-10 -top-3 sm:-top-7 md:-top-5"
            />
            Understanding the Keto Diet
          </h2>
          <p className="relative text-base sm:text-lg 2xl:text-2xl font-normal">
          The <strong>ketogenic diet</strong> helps your body <strong>burn fat for fuel, boost energy levels</strong>, and <strong>improve overall health</strong>-all with a <strong>personalized, guided plan</strong> designed just for you.
            <img
              src={Icn3}
              className="w-7 absolute hidden md:block md:left[0%] lg:left-[0%] -bottom-2"
            />
            <img src={Icn2} className="absolute block sm:hidden right-0 -top-3"/>
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 pt-3 sm:pt-10 gap-3 sm:gap-10 place-items-center">
          <div>
            <img src={Burgur} alt="Logo" className="m-auto" />
          </div>
          <div>
            <h3 class="text-lg sm:text-3xl font-bold mb-2 lg:mb-4">The Science Behind Keto - Why It Works</h3>
            <p className="text-base sm:text-lg 2xl:text-2xl font-normal mb-2 lg:mb-4"> The <strong>ketogenic diet</strong> is a <strong>cutting-edge, low-carb, high-fat approach</strong> designed to <strong>transform your body efficiently and sustainably</strong>.</p>
            <ul className="list-disc pl-5 text-base sm:text-lg 2xl:text-2xl leading-6 sm:leading-8">
              <li className="mb-2">
                <strong>Shifts your metabolism into ketosis</strong> - Your body burns fat for fuel instead of carbs.
              </li>
              <li className="mb-2">
              <strong>Provides sustained energy</strong> - Your body burns fat for fuel instead of carbs.
              </li>
              <li className="mb-2">
              <strong>Shifts your metabolism into ketosis</strong> - No more crashes, just steady, all-day energy.
              </li>
              <li className="mb-2">
              <strong>Boosts overall health & well-being</strong> - Supports metabolism, mental clarity, and weight management.
              </li>
              <li className="mb-2">
              <strong>Maximizes results with a personalized plan</strong> - Tailored to fit <strong> your goals and lifestyle</strong>.
              </li>
              <li className="mb-2"><strong>Creates a highly efficient fat-burning system</strong> - Helping you stay in shape effortlessly.</li>
              <li className="mb-2">
              <strong>Equips you for long-term success</strong> - A <strong>sustainable</strong>, healthy way to live and thrive..
              </li>
             
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnderstandingKeto;
