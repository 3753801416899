import {useEffect} from 'react'

import Header from '../component/Header'
import Footer from '../component/Footer'
import TopFooter from '../component/TopFooter'
import NewsLetter from '../component/NewsLetter'
import Serachlogo from '../assets/images/search.png'
import rightarrow from '../assets/images/rightarrow.png'
import { Link } from 'react-router-dom'
import iphone1  from "../assets/app/iPhone-1.png"
import iphone2  from "../assets/app/iPhone-2.png"
import iphone3  from "../assets/app/iPhone-3.png"
import iphone4  from "../assets/app/iPhone-4.png"
import iphone5  from "../assets/app/iPhone-5.png"
import iphone6  from "../assets/app/iPhone-6.png"

const HowUseApp = () => {
    useEffect(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, []);
    return (
        <div className='bg-custombgColor'>
            <Header />
            <div className="px-5 md:px-0 md:mx-24 sm:mx-12 mb-24">
        <div className="md:pb-12 md:pt-12 pt-12 pb-5 flex flex-col md:flex-row justify-between border-btntextColor">
          <div className="max-w-96">
            <div className="bordctex flex gap-1">
              <Link to="/help-center">Help center</Link>{" "}
              <img
                src={rightarrow}
                alt="rightarrow"
                className=" h-3 mx-1 my-auto "
              />
              <Link to="/how-to-use">How to use</Link>{" "}
              <img
                src={rightarrow}
                alt="rightarrow"
                className=" h-3 mx-1 my-auto "
              />
              <Link to="/how-to-use-app">How to use App</Link>
            </div>
          </div>
          <div className="flex flex-row items-center space-x-4 max-w-[500px] ">
            <div className="relative max-w-[350px] ">
              <input
                type="search"
                id="searchteat"
                placeholder="Search your keyword hear..."
                className="pl-10 pr-4 py-2 border border-headingColor rounded-[40px] focus:outline-none focus:ring-2 focus:ring-slate-200 placeholder:text-base placeholder:font-normal placeholder:text-[#bebbbb] h-12 w-full"
              />
            </div>
            <button className="bg-btntextColor text-white rounded-[40px] focus:outline-none text-base sm:text-lg 2xl:text-2xl font-bold w-12 h-12">
              <img
                src={Serachlogo}
                alt="search"
                className="w-6 h-6 text-center m-auto"
              />
            </button>
          </div>
        </div>
        <div>
          <h1 className="text-5xl sm:text-5xl md:text-6xl lg:text-[64px] mb-7 text-center font-extrabold">
            {" "}
            How to use ketobalanced App{" "}
          </h1>

          <figure className='m-auto max-w-screen-lg'>
          <video width="100%" height="360" autoPlay muted loop>
            <source src="https://kbcdn22.ketobalanced.com/a2V0b2JhbGFuY2VkLW1vYmlsZS1hcHAtZ3VpZGUtdmlkZW8=.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </figure>


    {/* <!------------section-1-------------------> */}

          <h3 className=" font-bold text-[32px]" id="navigate-through-app">
            Navigate through the App
          </h3>

          <div className="md:grid md:grid-cols-12 gap-8 items-center mt-12">
            <div className="col-span-4">
              <img
                src={iphone1}
                alt="Log Meals"
                className="m-auto"
              />
            </div>
            <div className="col-span-8">             
              <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
                  Our website has 4 sections:
              </p>              
              <ul className="list-disc pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
                  <li className="mb-3" style={{lineHeight:"1.5"}}><b>Home:</b> Find your daily goals, and target in this section. Keep monitoring your daily activities, water intake, and meals.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}><b>Meal Plans:</b> You can see that each day is broken down into three main meals and two snacks when you open your meal plan. Alternatives are also available. After every meal, select Log to track your progress (or mark Skip if you didn't consume the meal).
                  Quick-access button (+): You can track the key nutrition and health statistics more </li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}><b>Quick-access button (+):</b> You can track the key nutrition and health statistics more quickly by using the quick access button in the middle of the menu.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}><b>Workouts:</b> This is where you'll find your weekly workouts. Create an entry to remember which of your workouts are finished</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}><b>Profile:</b> Edit your personal information, find the Beginners Guide, and gain access to premium services.</li>
              </ul>
              <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
                 Remember:
              </p>
              <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
              Check out the menu on the left side of this page if you're seeking for specific instructions on how to use a certain app feature. You may find our more thorough explanations useful.
              </p> 
            </div>
          </div>


        {/* <!------------section-2-------------------> */}

          <h3 className=" font-bold text-[32px] mt-10" id="navigate-through-app">
          Track Hydration
          </h3>

          <div className="md:grid md:grid-cols-12 gap-8 items-center">
          <div className="col-span-8">             
              <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
              Monitoring your water intake is fairly simple.
              </p>              
              <ul className="list-disc pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Scroll to the Nutrition section under the Home tab.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Tap Water Intake</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>You will be shown your daily data. Click on how much water you had, then select Log water.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Using the + button located in the middle of the bottom menu will add water quickly. Water + tap. Choose how much to drink, then turn on the water tap.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Track Steps</li>
                  
              </ul>
               
            </div>
            <div className="col-span-4">
              <img
                src={iphone2}
                alt="Log Meals"
                className="m-auto"
              />
            </div>
            
          </div>




           {/* <!------------section-3-------------------> */}

           <h3 className=" font-bold text-[32px] mt-10" id="navigate-through-app">
           Track your Daily Footstep
          </h3>

          <div className="md:grid md:grid-cols-12 gap-8 items-center mt-10">
          <div className="col-span-4">
              <img
                src={iphone3}
                alt="Log Meals"
                className="m-auto"
              />
            </div>
          <div className="col-span-8">           
                           
              <ul className="list-disc pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Scroll down to Track your steps in the Home tab.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Click Tracking to begin.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>In the pop-up box, consent to the step-tracking software on your phone.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Apple Health and Google Fit are supported by Keto Balanced. utilize the same email address.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Track Calorie Intake</li>
                                   
              </ul>
               
          </div>            
          </div>



          {/* <!------------section-4-------------------> */}

          <h3 className=" font-bold text-[32px] mt-10" id="navigate-through-app">
          Meals section
          </h3>

          <div className="md:grid md:grid-cols-12 gap-8 items-center">
          <div className="col-span-8">             
              <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
              You don't need to be anxious about monitoring your calorie intake if you're using the KetoBalanced meal plan. When you mark meals as Log in the app's Meals section, this happens automatically.
              </p>  
              <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
              To add custom meals, take the following actions:
              </p>             
              <ul className="list-disc pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Go to the Meals area in the menu at the bottom.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Scroll past the meal plan for your Keto Balanced.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Select Log your meal+.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>If you ate at a restaurant, select Eating out or Create my meal.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Look up or write down your recipe, choose the time it was made, and save it.</li>
                  <li className="mb-3" style={{lineHeight:"1.5"}}>Add Your Own Meal</li> 
              </ul>               
            </div>
            <div className="col-span-4">
              <img
                src={iphone4}
                alt="Log Meals"
                className="m-auto"
              />
            </div>
            
          </div>


          {/* <!------------section-5-------------------> */}

          <h3 className=" font-bold text-[32px] mt-10" id="navigate-through-app">
          For Custom Meals
          </h3>

          <div className="md:grid md:grid-cols-12 gap-8 items-center mt-10">
          <div className="col-span-4">
              <img
                src={iphone5}
                alt="Log Meals"
                className="m-auto"
              />
            </div>
          <div className="col-span-8"> 
          
                            <ul className="list-disc pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
                                <li className='mb-1'>Start the KetoBalanced app.</li>
                                <li className='mb-1'>Go to the Meals area in the menu at the bottom.</li>
                                <li className='mb-1'> To get the button to Log your Meal, scroll past the suggested meals.
                                </li>
                                <li className='mb-1'>Select Create my Meal.</li>
                                <li className='mb-1'> The ingredient search can be used to create your recipe. Just add the
                                    ingredients and their quantities.</li>
                                <li className='mb-1'>Tap Continue once you're finished.</li>
                                <li className='mb-1'>Enter the meal's name and choose the time it was consumed. </li>
                                <li className='mb-1'>Click Save. </li>
                                <li className='mb-1'>You may also use the barcode scanner feature (next to the search box).
                                </li>
                                <li className='mb-1'>Click +Add ingredient after scanning the barcode or manually entering
                                    it.</li>
                                <li className='mb-1'>Choose Enter nutrition values manually if you can't find what you're
                                    looking for. </li>
                                <li className='mb-1'>Tap Create after entering the meal's name and nutritional details.</li>
                                <li className='mb-1'>Favourites</li>

                            </ul>
                            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>You can favourite any recipe you like by clicking on the Heart icon at the top-right corner. Open the meal that you would like to cook, and tap Change to change the meal you have been given by us.  Select the Favorites button from the list of options. The recipes you've saved as favorites can be found here.</p>
                            <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
                                <li className='mb-1'> Switch the Measurement System</li>
                                <li className='mb-1'> Open the KetoBalanced app.</li>
                                <li className='mb-1'> Click Profile &gt; View Your Profile in the bottom menu.</li>
                                <li className='mb-1'> Navigate to Settings &gt; Units.</li>
                                <li className='mb-1'> Metric or imperial units are available, select whichever you are most comfortable with.</li>
                                <li className='mb-1'> Customise Diet</li>

                            </ul>
               
          </div>            
          </div>



             {/* <!------------section-6-------------------> */}

          <h3 className=" font-bold text-[32px] mt-10" id="navigate-through-app">
          App Settings
          </h3>

          <div className="md:grid md:grid-cols-12 gap-8 items-center">
          <div className="col-span-8">             
          <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>We believe in not giving up on your favourite foods (that doesn’t include carbs) so you can change your diet preferences however you feel. </p>
                            <ul className="list-disc pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
                                <li className='mb-1'>Open the KetoBalanced app.</li>
                                <li className='mb-1'>In the bottom menu, select the Profile section.</li>
                                <li className='mb-1'>To change your meal settings, select View Your Profile &gt; Settings.
                                    We'll also ask you to take the questionnaire again so we can make a customized meal plan just for you.
                                </li>
                                <li className='mb-1'>Change App Language </li>
                                <li className='mb-1'>Start the app.</li>
                                <li className='mb-1'>Visit Profile.</li>
                                <li className='mb-1'>Select Language under View Your Profile &gt; Settings.</li>
                                <li className='mb-1'>From the drop-down menu, select your language.</li>
                                <li className='mb-1'>App Lagging or Malfunction</li>
                            </ul>
                            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>Reset App:</p>
                            <ul className="list-disc pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
                                <li className='mb-1'>Open the KetoBalanced app.</li>
                                <li className='mb-1'>In the bottom-right corner, tap on "Your Profile."</li>
                                <li className='mb-1'>Go to View Your Profile &gt; Settings&gt;Reset the program&gt;Yes</li>
                            </ul>
                            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}> You can also try to Reinstall the App, after Deleting it. Use the Contact Us button found below this page to get in touch with Our Support, if the app is still not functioning.</p>           
            </div>
            <div className="col-span-4">
              <img
                src={iphone6}
                alt="Log Meals"
                className="m-auto"
              />
            </div>
            
          </div>



        </div>
      </div>
            <NewsLetter />
            <TopFooter />
            <Footer />
        </div>
    )
}

export default HowUseApp
