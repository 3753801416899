import React, { useEffect } from "react";
import searchlens from "../assets/helpcenter/search-lens.png";
import subscriptionicon from "../assets/helpcenter/subscription-icon.png";
import appicon from "../assets/helpcenter/app-icon.png";
import generalquestion from "../assets/helpcenter/general-question-icon.png";
import paymenticon from "../assets/helpcenter/payment-icon.png";
import Footer from "../component/Footer";
import TopFooter from "../component/TopFooter";
import NewsLetter from "../component/NewsLetter";
import Header from "../component/Header";
import arrowImg1 from "../assets/helpcenter/arrow-1.png";
import arrowImg2 from "../assets/images/arrow/support-anytime-4.svg";
import arrowImg3 from "../assets/helpcenter/arrow-3.png";
import marrowImg1 from "../assets/helpcenter/m-arrow-1.png";
import marrowImg2 from "../assets/helpcenter/m-arrow-2.png";
import { Link } from "react-router-dom";

const HelpCenter = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header />
      <div className="w-full bg-custombgColor">
        <div className="px-4 md:px-14 overflow-hidden">
          {/* search section  */}

          <div className="container m-auto">
            <div className="py-14 flex flex-col md:flex-row justify-between gap-4 xl:gap-10 items-center">
              <div className="w-full md:w-2/3 lg:w-1/3 relative">
                <h3 className="text-[34px] font-extrabold text-center md:text-left">
                  <span className="text-[#417421]">Hello!</span> How can we
                  assist you and make your day better?
                </h3>
                <img
                  src={arrowImg1}
                  alt="Arrow"
                  className="hidden md:block absolute -bottom-20 left-20"
                />
              </div>

              <div className="flex items-center justify-center md:justify-end gap-2 max-w-[350px] w-full md:w-2/3 lg:w-1/3 relative">
                <img
                  src={arrowImg2}
                  alt="arrowImg2"
                  className="absolute hidden lg:block -left-[50%]  xl:-left-[40%] -top-5"
                />

                <div className="max-w-[280px] w-full relative z-10">
                  <input
                    type="email"
                    id="username"
                    placeholder="Search your keyword here..."
                    className="pl-10 pr-4 py-2 border border-headingColor rounded-[40px] focus:outline-none focus:ring-2 focus:ring-slate-200 placeholder:text-base placeholder:font-normal placeholder:text-[#bebbbb] h-12 w-full"
                  />
                </div>
                <button className="bg-btntextColor text-white rounded-[40px] focus:outline-none text-lg font-bold w-[48px] h-[48px] mt-2 sm:mt-0 flex justify-center items-center">
                  <img
                    src={searchlens}
                    alt="lens Icon"
                    className="w-1/2 justify-self-center"
                  />
                </button>
              </div>
            </div>
          </div>

          {/* Box section */}

          <div className="container m-auto">
            <div className="py-12 px-6 md:px-0 mb-20 relative">
              <img
                src={arrowImg3}
                alt="arrowImg3"
                className="hidden md:block absolute -right-[60px] -top-[25px]"
              />
              <img
                src={marrowImg1}
                alt="arrowImg3"
                className="block md:hidden absolute top-0 -left-3"
              />
              <img
                src={marrowImg2}
                alt="arrowImg3"
                className="block md:hidden absolute -top-3 -right-6"
              />
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 rounded-lg text-center">
                <div className="rounded-3xl bg-[#FCFFF2] p-8 lg:p-12">
                  <Link to="/general">
                    <img src={generalquestion} className=" mb-5 m-auto" />
                    <h6 className="text-xl font-extrabold py-3">
                      General Questions
                    </h6>
                    <p className="text-base sm:text-lg ">
                      Got questions? We're here to provide the answers you need!
                    </p>
                  </Link>
                </div>
                <div className="rounded-3xl bg-[#FCFFF2] p-8 lg:p-12">
                  <Link to="/subscription">
                    {" "}
                    <img src={subscriptionicon} className="mb-5 m-auto" />
                    <h6 className="text-xl font-extrabold py-3">
                      Subscription Management
                    </h6>
                    <p className="text-base sm:text-lg">
                      Need help with your subscription? We're here to assist
                      you!
                    </p>
                  </Link>
                </div>
                <div className="rounded-3xl bg-[#FCFFF2] p-8 lg:p-12">
                  <Link to="/paymentinfo">
                    {" "}
                    <img src={paymenticon} className="mb-5 m-auto" />
                    <h6 className="text-xl font-extrabold py-3">
                      Payment Information
                    </h6>
                    <p className="text-base sm:text-lg">
                      Questions about payment? We're here to assist with
                      everything!
                    </p>
                  </Link>
                </div>
                <div className="rounded-3xl bg-[#FCFFF2] p-8 lg:p-12">
                  <Link to="/how-to-use">
                    {" "}
                    <img src={appicon} className="mb-5 m-auto" />
                    <h6 className="text-xl font-extrabold py-3">
                      How to use app
                    </h6>
                    <p className="text-base sm:text-lg">
                      Wondering how to use the app? We're here to guide you!
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewsLetter />
        <TopFooter />
        <Footer />
      </div>
    </>
  );
};

export default HelpCenter;
