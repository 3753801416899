import {useEffect} from 'react'

import Header from '../component/Header'
import Footer from '../component/Footer'
import TopFooter from '../component/TopFooter'
import NewsLetter from '../component/NewsLetter'
import Serachlogo from '../assets/images/search.png'
import rightarrow from '../assets/images/rightarrow.png'
import { Link } from 'react-router-dom'

const Subscription = () => {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, []);
    return (
        <div className='bg-custombgColor'>
            <Header />
            <div className='px-5 md:px-0 md:mx-24 sm:mx-12'>
                <div className='md:pb-12 md:pt-12 pt-12 pb-5 flex flex-col md:flex-row justify-between border-btntextColor'>
                    <div className='max-w-96'>
                        <div className='bordctex flex gap-1'>
                            <Link to="/help-center" >Help center</Link> <img src={rightarrow} alt='rightarrow' className=' h-3 mx-1 my-auto ' />
                            <Link to="/subscription" >Subscription Management</Link>
                        </div>
                    </div>
                    <div className="flex flex-row items-center space-x-4 max-w-[500px] ">
                        <div className="relative max-w-[350px] ">
                            <input
                                type="search"
                                id="searchteat"
                                placeholder="Search your keyword hear..."
                                className="pl-10 pr-4 py-2 border border-headingColor rounded-[40px] focus:outline-none focus:ring-2 focus:ring-slate-200 placeholder:text-base placeholder:font-normal placeholder:text-[#bebbbb] h-12 w-full"
                            />
                        </div>
                        <button className="bg-btntextColor text-white rounded-[40px] focus:outline-none text-lg font-bold w-12 h-12">
                            <img src={Serachlogo} alt='search' className='w-6 h-6 text-center m-auto' />
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-5 mb-20">
                    {/* Main Content */}
                    <div className="col-span-12 md:col-span-9 order-last md:order-first">
                        <h1 className="text-5xl sm:text-5xl md:text-6xl lg:text-[64px] mb-7 font-extrabold">Subscription Management</h1>
                        <h3 className="font-bold text-[32px] mb-3" id="how-pause-your-subscription">How to Pause your Subscription?</h3>
                        <div>
                            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>You have the option to pause your membership whenever you choose.</p>
                            <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl ">
                                <li className='mb-3' style={{lineHeight:"1.5"}}>Go to <a href="https://ketobalanced.com">https://ketobalanced.com</a> to access the Keto Balanced website.</li>
                                <li className='mb-3' style={{lineHeight:"1.5"}}>Click the Login button at the top.</li>
                                <li className='mb-3' style={{lineHeight:"1.5"}}> Use the same email address and password you use for the app to log in.</li>
                                <li className='mb-3' style={{lineHeight:"1.5"}}>Tap the profile icon &gt; Subscription in the top-right corner.</li>
                                <li className='mb-3' style={{lineHeight:"1.5"}}> Select the length of the pause by clicking Pause Subscription, then press Pause Subscription once more.</li>
                                <li className='mb-3' style={{lineHeight:"1.5"}}> Select the cause of the pause, then click Submit.</li>
                            </ul>
                            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>Your subscription can be put on hold for up to 30 days, and you can resume it whenever you want on the same website.</p>
                        </div>
                        <h3 className="font-bold text-[32px] mb-3 mt-5" id="how-cancel">How to Cancel?</h3>
                        <div>
                            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>Note that our 3, 6, and 12-month membership plans are all recurring, and they all renew themselves when they run out of time. Please cancel your membership renewal if you decide not to continue with the program in order to stop being charged moving forward.
                                Depending on where you bought it, you may need to manage your KetoBalanced membership. However, you can do it anytime, and it is pretty simple. </p>
                                <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-5 mb-2"> For <i>Subscription purchased via website:</i></p>
                            <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl ">
                                <li className='mb-3' style={{lineHeight:"1.5"}}>On the web page of your profile, you can find and manage the details of your membership to the meal plan.</li>
                                <li className='mb-3' style={{lineHeight:"1.5"}}>Please select My Profile &gt; Manage your subscription after signing in with your email address and password. You can check the status of your subscription and, if necessary, cancel it here.</li>
                                <li className='mb-3' style={{lineHeight:"1.5"}}>You can cancel your KetoBalanced subscription renewal at any moment through the subscription period by sending a cancellation request via the Contact us button below if you're having any issues logging into your account or need any other help.</li>
                            </ul>
                            <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-5 mb-2"><i>For Subscription via Google Play: </i></p>
                            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}><a className='break-words' href="https://support.google.com/googleplay/answer/7018481 ">https://support.google.com/googleplay/answer/7018481 </a></p>
                            <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-5 mb-2"><i>For Subscription via Apple Store: </i></p>
                            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>Follow the steps on the website: <a href="https://support.apple.com/en-us/HT202039">https://support.apple.com/en-us/HT202039</a> </p>
                            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>Note; Deleting the app will not cancel your subscription. Make sure to follow the steps above and see a confirmation that the subscription has been canceled.</p>
                            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>If you have already tried to follow the instructions above but can't find the option to cancel, it could be that you're checking on the wrong platform. For example, if KetoBalanced doesn't show up among your Apple subscriptions, try to cancel your membership on the KetoBalanced app.</p>
                        </div>
                    </div>
                    {/* Sidebar */}
                    <div className="col-span-12 md:col-span-3 leftboox md:sticky top-24  min-h-fit md:h-[475px] rounded-3xl">
                        <div className="px-5 py-4 md:px-6 md:py-8">
                            <ol className='litex space-y-2 pl-4'>
                                <li className='mb-3' style={{lineHeight:"1.5"}}><a href="#how-pause-your-subscription" className="hover:underline">How to Pause your Subscription?</a></li>
                                <li className='mb-3' style={{lineHeight:"1.5"}}><a href="#how-cancel" className="hover:underline">How to Cancel?</a></li>
                            </ol>
                        </div>
                    </div>
                </div></div>
            <NewsLetter />
            <TopFooter />
            <Footer />
        </div>
    )
}

export default Subscription;