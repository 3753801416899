import React, { useState } from "react";
import SlideImage1 from "../assets/images/slide-image4.png";
import SlideImage2 from "../assets/images/slide-image5.png";
import SlideImage3 from "../assets/images/slide-image6.png";
import MobileBanner1 from "../assets/images/mobile-banner4.png";
import MobileBanner2 from "../assets/images/mobile-banner5.png";
import MobileBanner3 from "../assets/images/mobile-banner6.png";
import Leaf5 from "../assets/images/arrow/leaf5.png";
import Icn5 from "../../src/assets/images/arrow/icn5.png";
import avc2 from "../assets/images/avc-img.png";
import avoto from "../assets/images/avoto.svg";
import line1 from "../assets/images/line-1.png";
import jrnyAro from "../assets/images/journy-arrow.svg";
const ThousandsTransformed = () => {
  const [activeSection, setActiveSection] = useState("first");
  const handleMouseEnter = (section) => {
    setActiveSection(section);
  };
  return (
    <>
      <div className="px-4 pb-12">
        <div className="max-w-[840px] 2xl:max-w-[940px] m-auto px-8 md:px-5">
          <h2 className="relative mb-7 text-start md:text-center font-extrabold text-4xl leading-10 md:text-[64px] sm:text-5xl 2xl:text-7xl md:leading-[80px] 2xl:leading-[98px]">
            {/* <img
              src={Leaf5}
              className="w-5 sm:w-[35px] md:w-[40px] lg:w-[45px] absolute -left-5 sm:-left-10 md:-left-6 lg:-left-14 -top-2 sm:-top-7"
            /> */}
            <span className="relative">Thousands</span> transformed with KetoBalanced
            <img
              src={Icn5}
              className="w-7 absolute hidden md:block sm:right-[-10%] md:right-[0%] lg:right-[0%] top-16 md:top-20 lg:top-20"
            />
          </h2>
        </div>

        <div className="flex flex-col lg:flex-row gap-5 justify-center">
          <div
            className={`lg:min-h-[600px] bg-[#FCFFF2] h-full relative flex max-w-[840px] md:w-full  md:mx-auto lg:w-auto lg:mx-0
 text-black rounded-[37px] overflow-hidden ${
   activeSection === "first" ? "active" : ""
 }`}
            onMouseEnter={() => handleMouseEnter("first")}
          >
            <div className=" hidden lg:block w-[180px] relative img-slide transition-all duration-300 ease-linear ">
              <img
                src={SlideImage1}
                alt="Slider img1"
                className="h-full object-cover"
              />

              <div className="absolute bottom-0 left-0 slidetitle z-10 bg-gradient-to-t from-[#000000bf] to-[#ffffff03] block w-full rounded-bl-[38px] rounded-br-[38px]">
                <div className="text-white px-3 py-10 shrtdesc">
                  <h3 className="text-lg sm:text-xl font-bold mb-2">
                    My Keto Journey Begins{" "}
                  </h3>
                  <p className="text-xs sm:text-[13.4px]">
                    Starting keto with KetoBalanced...
                  </p>
                </div>
              </div>
            </div>
            <img
              src={activeSection === "first" ? SlideImage1 : MobileBanner1}
              className={`${
                activeSection === "first"
                  ? "max-w-[140px] md:max-w-[250px] object-cover"
                  : ""
              } lg:hidden w-full`}
            />

            <div className="flex-1 relative p-4 pl-8 px-2 sm:px-4 sm:pl-10 lg:px-10 pt-6 sm:pt-10 lg:pt-14 pb-24 boxslide transition-all duration-300 ease-linear">
              <h3 className="text-lg sm:text-3xl font-bold mb-4 lg:mb-8 relative inline-block">
              <img src={line1} className=" block md:hidden absolute left-0 -bottom-3"/>
                My Keto Journey Begins
                <img
                  src={avc2}
                  className="absolute w-5 sm:w-6 -left-[22px] sm:-left-[23px] -top-[15px] sm:-top-[15px]"
                />
                
              </h3>
              <p className="text-xs sm:text-base md:text-lg">
                Starting keto with KetoBalanced was one of the best decisions
                I've ever made. The meal plans were easy to follow and packed
                with flavor, which kept me on track and motivated. I didn't need
                to hit the gym to see results—the tailored meals worked wonders,
                and I started seeing amazing changes in my body without extra
                effort.
              </p>

              <img
                src={avoto}
                className="absolute bottom-8 left-4 sm:left-8 w-6 sm:w-8 md:w-9"
              />
              <img
                src={jrnyAro}
                className="absolute bottom-3 md:bottom-8 max-w-20 md:max-w-full right-3"
              />
            </div>
          </div>

          {/* Second Section */}
          <div
            className={`lg:min-h-[600px] h-full relative flex max-w-[840px] md:w-full  md:mx-auto lg:w-auto lg:mx-0
 text-black bg-[#FCFFF2] rounded-[37px] overflow-hidden ${
   activeSection === "second" ? "active" : ""
 }`}
            onMouseEnter={() => handleMouseEnter("second")}
          >
            <div className="hidden w-[360px] relative img-slide transition-all duration-300 ease-linear lg:flex flex-row sm:flex-col ">
              <img
                src={SlideImage2}
                alt="Slider img1"
                className="h-full object-cover"
              />

              <div className="absolute bottom-0 left-0 slidetitle z-10 bg-gradient-to-t from-[#000000bf] to-[#ffffff03] block w-full rounded-bl-[38px] rounded-br-[38px]">
                <div className="text-white px-3 py-10 shrtdesc">
                  <h3 className="text-lg sm:text-xl font-bold mb-2">
                    A Family-Friendly...
                  </h3>
                  <p className="text-xs sm:text-[13.4px]">
                    One of the best parts of this journey was...
                  </p>
                </div>
              </div>
            </div>

            <img
              src={activeSection === "second" ? SlideImage2 : MobileBanner2}
              className={`${
                activeSection === "second"
                  ? "max-w-[140px] md:max-w-[250px] object-cover"
                  : ""
              } lg:hidden w-full`}
            />

            <div className="flex-1 relative p-4 pl-8 px-2 sm:px-4 sm:pl-10 lg:px-10 pt-6 sm:pt-10 lg:pt-14 pb-24 boxslide transition-all duration-300 ease-linear">
              <h3 className="text-lg sm:text-3xl font-bold mb-4 lg:mb-8 relative inline-block">
                A Family-Friendly Transformation
                <img
                  src={avc2}
                  className="absolute w-5 sm:w-6 -left-[22px] sm:-left-[23px] -top-[15px] sm:-top-[15px]"
                />
                <img src={line1} className=" block md:hidden absolute left-0 -bottom-3"/>
              </h3>
              <p className="text-xs sm:text-base md:text-lg">
                One of the best parts of this journey was that my entire family
                enjoyed the meals! My husband and kids loved the variety and
                taste, which made it so much easier for me to stick to my plan.
                It wasn't just about me; we all shared the experience, and the
                whole family benefited from eating healthier together.
              </p>

              <img
                src={avoto}
                className="absolute bottom-8 left-4 sm:left-8 w-6 sm:w-8 md:w-9"
              />
              <img
                src={jrnyAro}
                className="absolute bottom-3 md:bottom-8 max-w-20 md:max-w-full right-3"
              />
            </div>
          </div>

          {/* Third Section */}
          <div
            className={`lg:min-h-[600px] h-full relative flex max-w-[840px] md:w-full  md:mx-auto lg:w-auto lg:mx-0
 text-black bg-[#FCFFF2] rounded-[37px] overflow-hidden ${
   activeSection === "third" ? "active" : ""
 }`}
            onMouseEnter={() => handleMouseEnter("third")}
          >
            <div className="w-[360px] relative img-slide transition-all duration-300 ease-linear hidden lg:block ">
              <img
                src={SlideImage3}
                alt="Slider img1"
                className="h-full object-cover"
              />
              <div className="absolute bottom-0 left-0 slidetitle z-10 bg-gradient-to-t from-[#000000bf] to-[#ffffff03] w-full rounded-bl-[38px] rounded-br-[38px] block">
                <div className="text-white px-3 py-10 shrtdesc">
                  <h3 className="text-lg sm:text-xl font-bold mb-2">
                    Results Beyond...
                  </h3>
                  <p className="text-xs sm:text-[13.4px]">
                    After losing 30 kgs, my husband jokes...
                  </p>
                </div>
              </div>
            </div>

            <img
              src={activeSection === "third" ? SlideImage3 : MobileBanner3}
              className={`${
                activeSection === "third"
                  ? "max-w-[140px] md:max-w-[250px] object-cover"
                  : ""
              } lg:hidden w-full`}
            />

            <div className="flex-1 relative p-4 pl-8 px-2 sm:px-4 sm:pl-10 lg:px-10 pt-6 sm:pt-10 lg:pt-14 pb-24 boxslide transition-all duration-300 ease-linear ">
              <h3 className="text-lg sm:text-3xl font-bold mb-4 lg:mb-8 relative inline-block">
                Results Beyond Expectations
                <img
                  src={avc2}
                  className="absolute w-5 sm:w-6 -left-[22px] sm:-left-[23px] -top-[15px] sm:-top-[15px]"
                />
                <img src={line1} className=" block md:hidden absolute left-0 -bottom-3"/> 
              </h3>
              <p className=" text-xs sm:text-base md:text-lg">
                After losing 30 kgs, my husband jokes that it's "unfair" how
                amazing I look now! KetoBalanced truly made this transformation
                effortless. The meals were so delicious, and the results were
                undeniable. I feel better, healthier, and more confident-and I
                couldn't be happier with how this journey has changed my life.
              </p>

              <img
                src={avoto}
                className="absolute bottom-8 left-4 sm:left-8 w-6 sm:w-8 md:w-9"
              />
              <img
                src={jrnyAro}
                className="absolute bottom-3 md:bottom-8 max-w-20 md:max-w-full right-3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThousandsTransformed;
