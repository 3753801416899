import React from 'react'
import Slider from './Slider'
import StartJourney from './StartJourney'
import Testimonial from './Testimonial'
const SliderWrap = () => {
  return (
    <>
    <div className='py-10 sm:py-24 bg-custombgColor overflow-hidden'>
    <Testimonial />
    <div className='xl:px-24  px-5'>
      <StartJourney /></div>
      <div className='sm:px-14 md:px-24 px-5'>
        <Slider />
      </div>
      
      </div>
    </>
  )
}

export default SliderWrap
