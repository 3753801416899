import React, { useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import AboutSection_1 from "../component/AboutSection_1";
import WhoWeAre from "../component/WhoWeAre";
import TopFooter from "../component/TopFooter";
import NewsLetter from "../component/NewsLetter";
import WeightlossGoal from "../component/WeightlossGoal";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header />
      <AboutSection_1 />
      <WhoWeAre />

      <div className="w-full bg-custombgColor">
        <WeightlossGoal />
      <NewsLetter /> 
        <TopFooter />
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
