import { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../component/Header";
import Footer from "../component/Footer";
import TopFooter from "../component/TopFooter";
import NewsLetter from "../component/NewsLetter";
import Serachlogo from "../assets/images/search.png";
import rightarrow from "../assets/images/rightarrow.png";
import loginaccount from "../assets/images/web/loginaccount.png";
import custommeal from "../assets/images/web/custommeal.png";
import navigation1 from "../assets/images/web/navigation.png";
import logmeal from "../assets/images/web/logmeal.png";

const HowUseWeb = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="bg-custombgColor">
      <Header />
      <div className="px-5 md:px-0 md:mx-24 mb-24 sm:mx-12">
        {/* Breadcrumb Navigation */}
        <div className="md:pb-12 md:pt-12 pt-12 pb-5 flex flex-col md:flex-row justify-between border-btntextColor">
          <div className="max-w-96">
            <div className="bordctex flex gap-1">
              <Link to="/help-center">Help center</Link>
              <img
                src={rightarrow}
                alt="rightarrow"
                className="h-3 mx-1 my-auto"
              />
              <Link to="/how-to-use">How to use</Link>
              <img
                src={rightarrow}
                alt="rightarrow"
                className="h-3 mx-1 my-auto"
              />
              <Link to="/how-to-use-web">How to use Web</Link>
            </div>
          </div>

          {/* Search Input */}
          <div className="flex flex-row items-center space-x-4 max-w-[500px]">
            <div className="relative max-w-[350px]">
              <input
                type="search"
                id="searchteat"
                placeholder="Search your keyword here..."
                className="pl-10 pr-4 py-2 border border-headingColor rounded-[40px] focus:outline-none focus:ring-2 focus:ring-slate-200 placeholder:text-base placeholder:font-normal placeholder:text-[#bebbbb] h-12 w-full"
              />
            </div>
            <button className="bg-btntextColor text-white rounded-[40px] focus:outline-none text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3 w-12 h-12">
              <img
                src={Serachlogo}
                alt="search"
                className="w-6 h-6 text-center m-auto"
              />
            </button>
          </div>
        </div>

        {/* Section - Login into your Account */}

        <div className="mb-10">
          <h1 className="text-5xl sm:text-5xl md:text-6xl lg:text-[64px] mb-7 text-center font-extrabold">
                      {" "}
                      How to use ketobalanced Web{" "}
                    </h1>
          
                    <figure className='m-auto max-w-screen-lg'>
                    <video width="100%" height="360" autoPlay muted loop>
                      <source src="https://kbcdn22.ketobalanced.com/a2V0b2JhbGFuY2VkLW1vYmlsZS1hcHAtZ3VpZGUtdmlkZW8=.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    </figure>
        </div>

        <div className=" flex flex-col-reverse md:grid md:grid-cols-12 gap-8 items-center">
          <div className="md:col-span-8 sm:col-span-12 ">
            <h3 className=" font-bold text-[32px] mb-3">Login into your Account</h3>
            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
              You can access your meal plan via the desktop website:{" "}
              <a href="https://user.ketobalanced.com/">
                https://user.ketobalanced.com/{" "}
              </a>
              You can access the plan by logging in using the email address you
              used to create your account. If the screen indicates that your
              password is wrong, click the Reset password button. You'll then
              get an email with a link to reset your password.
              <br />
              Please get in touch with our support staff using the contact form
              below if you haven't received a link to change your password.
            </p>
          </div>
          <div className="md:col-span-4 sm:col-span-12 sm:pt-2 mt-10 md:mt-0 ">
            <img
              src={loginaccount}
              alt="Login"
              className="w-auto h-auto m-auto"
            />
          </div>
        </div>

        {/* Section - Navigate on the Website */}
        <div className="md:grid md:grid-cols-12 gap-8 items-center mt-12">
          <div className="md:col-span-4 sm:col-span-12  ">
            <img
              src={navigation1}
              alt="Navigation"
              className="w-auto h-auto m-auto"
            />
          </div>
          <div className="md:col-span-8 sm:col-span-12">
            <h3 className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 mb-3">
              Navigate on the website
            </h3>
            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
              In the top-right corner of the screen, is the profile icon. There,
              you can change your settings for your workouts, meals, and
              personal information. You can also adjust your subscription and
              modify your password.
            </p>
            <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3">
              Our website has 4 sections:
            </p>
            <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                Home: Find your daily goals, and target in this section. Keep
                monitoring your daily activities, water intake, and meals.
              </li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                Meal Plans: Each day is broken down into three main meals and
                two snacks when you open your meal plan. Customisations are also
                available. Once you've finished the meal, choose Log. To look
                through the options, select Change.
              </li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                Shopping list: You can make your grocery list according to
                whatever ingredients you like, print said list, then even pick
                the days you need to be reminded to go shopping.
              </li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                Workouts: Your weekly workouts can be found here. The exercises
                you finish marking as finished.
              </li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                To Track your progress faster, we even have the Quick Log option
                in the Top Menu.
              </li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>Customization of Meals</li>
            </ul>
          </div>
        </div>

        {/* Section - Customize Your Meals */}
        <div className=" flex flex-col-reverse md:grid md:grid-cols-12 gap-8 items-center mt-12">
          <div className="md:col-span-8 sm:col-span-12 ">
            <h3 className="font-bold text-[32px] mb-3">Customize your meals</h3>

            <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
              <li className="mb-3" style={{lineHeight:"1.5"}}> Open the desktop meal plan.</li>
              <li className="mb-3" style={{lineHeight:"1.5"}}> Tap the Profile icon.</li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>Choose Meal Preferences.</li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                {" "}
                Select the meals you want to include from the menu.
              </li>
              <li className="mb-3" style={{lineHeight:"1.5"}}> To save and confirm, click.</li>
              <li className="mb-3" style={{lineHeight:"1.5"}}> Print Your Shopping List</li>
            </ul>
            <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3">
              Now, for Printing,
            </p>
            <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
              <li className="mb-3" style={{lineHeight:"1.5"}}>Open Ketobalanced on your desktop.</li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>Tap Shopping list from the top menu.</li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>Click Create My Shopping List.</li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                Select the shopping days you want to use, then click Done.
              </li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                At the top of your list, tap the tiny printer symbol.
              </li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>Step-Tracker</li>
            </ul>
            <p className="text-base sm:text-lg 2xl:text-2xl font-bold mt-2 2xl:mt-3">
              For tracking your daily footsteps:
            </p>
            <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                Open the website and at the top menu, select Home.
              </li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>Click Steps under the Activity section.</li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                You can edit your daily target or manually enter steps here.
              </li>
            </ul>
            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
              You will need an external pedometer or a way to track your steps
              on your phone or smartwatch in order to use this functionality.
              You can also use the KetoBalanced app, which has complete
              functionalities, if you want to connect it with Google Fit or
              Apple Health.
            </p>
          </div>
          <div className="md:col-span-4 sm:col-span-12 sm:pt-2 ">
            <img
              src={custommeal}
              alt="Customize meals"
              className="w-auto h-auto m-auto "
            />
          </div>
        </div>

        {/* Section - Log in Your Meals */}
        <div className="md:grid md:grid-cols-12 gap-8 items-center mt-12">
          <div className="md:col-span-4 sm:col-span-12 ">
            <img
              src={logmeal}
              alt="Log Meals"
              className="w-auto h-auto m-auto"
            />
          </div>
          <div className="md:col-span-8 sm:col-span-12">
            <h3 className=" font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 mb-3">
              Log In Your Meals
            </h3>
            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
              For Our Meal Plan, Open your online meal plan.Click Meals in the
              top menu. Select Log Meal &gt; Yes.
            </p>
            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
              For Your Custom Meals,
            </p>
            <ul className="list-decimal pl-6 leading-6 md:leading-7 text-base sm:text-lg 2xl:text-2xl">
              <li className="mb-3" style={{lineHeight:"1.5"}}>Click Meals in the top menu.</li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>Choose any meal or snack to log.</li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                For the meals you prepared, select Create My Meal or Eating Out
                if you had dinner out.
              </li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                To find restaurants or ingredients, use the Search option.
              </li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>Next to the foods you consumed,</li>
              <li className="mb-3" style={{lineHeight:"1.5"}}>
                Complete the remaining pertinent information and select
                Continue.
              </li>
            </ul>
            <p className="text-base sm:text-lg 2xl:text-2xl mb-3" style={{lineHeight:"1.5"}}>
              You can log in the meal you ate at the restaurant, simply by
              adding it under Custom Meal.
            </p>
          </div>
        </div>
      </div>

      {/* Footer Components */}
      <NewsLetter />
      <TopFooter />
      <Footer />
    </div>
  );
};

export default HowUseWeb;
