import React, { useState } from "react";
import SlideImage1 from "../assets/images/slide-image1.png";
import SlideImage2 from "../assets/images/slide-image2.png";
import SlideImage3 from "../assets/images/slide-image3.png";
import OK from "../assets/images/ok.svg";
import Leaf4 from "../assets/images/arrow/leaf4.png";
import Icn from "../../src/assets/images/arrow/icn.png";
import MobileBanner1 from "../assets/images/mobile-banner1.png";
import MobileBanner2 from "../assets/images/mobile-banner2.png";
import MobileBanner3 from "../assets/images/mobile-banner3.png";
import avc2 from "../assets/images/avc-img.png";
import avoto from "../assets/images/avoto.svg";
import line1 from "../assets/images/line-1.png";
import jrnyAro from "../assets/images/journy-arrow.svg";

const StartJourney = () => {
  const [activeSection, setActiveSection] = useState("first");
  const handleMouseEnter = (section) => {
    setActiveSection(section);
  };


  return (
    <>
      <div className="my-10 sm:my-24">
        <div className="max-w-[840px] 2xl:max-w-[1024px] m-auto">
          <h2 className="relative journrflowr mb-7 text-left sm:text-center font-extrabold text-4xl leading-10 md:text-[60px] lg:text-[64px] sm:text-5xl 2xl:text-7xl md:leading-[80px] 2xl:leading-[98px]">
           
            Kickstart Your Keto Journey the Right Way - Simple, Effective, and
            Personalized!
          </h2>
          <p className="mb-10 font-normal text-base sm:text-lg 2xl:text-2xl max-w-[840px] m-auto text-left sm:text-center">
           
            <span className="relative journyPra">Achieve</span> your health goals effortlessly with{" "}
            <strong>KetoBalanced!</strong> Let us handle the science and
            planning while you <strong>embrace the ketogenic lifestyle</strong>{" "}
            and transform your life with ease and confidence.
            
          </p>
        </div>

        <div className="flex flex-col lg:flex-row gap-5 justify-center">
          <div
            className={`lg:min-h-[600px] h-full relative flex max-w-[840px] md:w-full  md:mx-auto lg:w-auto lg:mx-0
 text-black bg-[#FCFFF2] rounded-[37px] overflow-hidden ${
   activeSection === "first" ? "active" : ""
 }`}
            onMouseEnter={() => handleMouseEnter("first")}
          >
            <div className=" hidden lg:block w-[180px] relative img-slide transition-all duration-300 ease-linear">
              <img
                src={SlideImage1}
                alt="Slider img1"
                className="h-full object-cover"
              />

              <div className="absolute bottom-0 left-0 slidetitle z-10 bg-gradient-to-t from-[#000000bf] to-[#ffffff03] block w-full rounded-bl-[38px] rounded-br-[38px]">
                <div className="text-white px-3 py-10 shrtdesc">
                  <h3 className="text-lg sm:text-xl font-bold mb-2">
                    Tailored Just for You
                  </h3>
                  <p className="text-xs sm:text-[13.4px]">
                    Generic diets fail because they don't cater...
                  </p>
                </div>
              </div>
            </div>
            <img
              src={activeSection === "first" ? SlideImage1 : MobileBanner1}
              className={`${
                activeSection === "first"
                  ? "max-w-[140px] md:max-w-[250px] object-cover"
                  : ""
              } lg:hidden w-full`}
            />

            <div className="flex-1 relative p-4 pl-8 px-2 sm:px-4 sm:pl-10 lg:px-10 pt-6 sm:pt-10 lg:pt-14 pb-24 boxslide transition-all duration-300 ease-linear">
              <h3 className="text-lg sm:text-3xl font-bold mb-4 lg:mb-6 relative inline-block">
              <img src={line1} className=" block md:hidden absolute left-0 -bottom-3"/>
                Tailored Just for You
                <img
                  src={avc2}
                  className="absolute w-5 sm:w-6 -left-[22px] sm:-left-[23px] -top-[15px] sm:-top-[15px]"
                />
                
              </h3>
              <p className="text-xs sm:text-base md:text-lg">
                Generic diets fail because they don't cater to{" "}
                <strong>your unique needs</strong>. At{" "}
                <strong>KetoBalanced</strong>, we{" "}
                <strong>prioritize you</strong> by crafting personalized meal
                plans that fit{" "}
                <strong>
                  your lifestyle, health goals, and food preferences
                </strong>
                , ensuring <strong>sustainable and rapid results</strong>.
              </p>
              <ul className="mt-4 md:mt-5">
                <li className="flex gap-1 items-baseline mb-1 md:mb-3 font-bold text-xs md:text-base lg:text-lg">
                  <img src={OK} alt="ok" className="w-3 md:w-4" /> Meals Meals
                  designed around your tastes
                </li>
                <li className="flex gap-1 items-baseline mb-1 md:mb-3 font-bold text-xs md:text-base lg:text-lg">
                  <img src={OK} alt="ok" className="w-3 md:w-4" /> Flexible
                  Flexible options for every lifestyle
                </li>
                <li className="flex gap-1 items-baseline mb-1 md:mb-3 font-bold text-xs md:text-base lg:text-lg">
                  <img src={OK} alt="ok" className="w-3 md:w-4" /> Hundreds of
                  delicious, customized recipes
                </li>
              </ul>

              <img
                src={avoto}
                className="absolute bottom-8 left-4 sm:left-8 w-6 sm:w-8 md:w-9"
              />
              <img
                src={jrnyAro}
                className="absolute bottom-3 md:bottom-8 max-w-20 md:max-w-full right-3"
              />
            </div>
          </div>

          {/* Second Section */}
          <div
            className={`lg:min-h-[600px] h-full relative flex max-w-[840px] md:w-full  md:mx-auto lg:w-auto lg:mx-0
  text-black bg-[#FCFFF2] rounded-[37px] overflow-hidden ${
    activeSection === "second" ? "active" : ""
  }`}
            onMouseEnter={() => handleMouseEnter("second")}
          >
            <div className="hidden w-[360px] relative img-slide transition-all duration-300 ease-linear lg:flex flex-row sm:flex-col">
              <img
                src={SlideImage2}
                alt="Slider img1"
                className="h-full object-cover"
              />

              <div className="absolute bottom-0 left-0 slidetitle z-10 bg-gradient-to-t from-[#000000bf] to-[#ffffff03] block w-full rounded-bl-[38px] rounded-br-[38px]">
                <div className="text-white px-3 py-10 shrtdesc">
                  <h3 className="text-lg sm:text-xl font-bold mb-2">
                    Proven & Practical...
                  </h3>
                  <p className="text-xs sm:text-[13.4px]">
                    The ketogenic diet is a game...
                  </p>
                </div>
              </div>
            </div>

            <img
              src={activeSection === "second" ? SlideImage2 : MobileBanner2}
              className={`${
                activeSection === "second"
                  ? " max-w-[140px] md:max-w-[250px] object-cover"
                  : ""
              } lg:hidden w-full`}
            />

            <div className="flex-1 relative p-4 pl-8 px-2 sm:px-4 sm:pl-10 lg:px-10 lg:px-10 pt-6 sm:pt-10 lg:pt-14 pb-24 boxslide transition-all duration-300 ease-linear">
              <h3 className="text-lg sm:text-3xl font-bold mb-4 lg:mb-6 relative inline-block">
              <img src={line1} className=" block md:hidden absolute left-0 -bottom-3"/>
                Proven & Practical - Keto Done Right
                <img
                  src={avc2}
                  className="absolute w-5 sm:w-6 -left-[22px] sm:-left-[23px] -top-[15px] sm:-top-[15px]"
                />
                
              </h3>
              <p className="text-xs sm:text-base md:text-lg">
                The <strong>ketogenic diet</strong> is a game-changer for{" "}
                <strong>weight loss, energy, and overall wellness</strong>-but
                success comes from <strong>doing it the right way</strong>.
                That's where we come in! At <strong>KetoBalanced</strong>, we
                take the guesswork out of keto with{" "}
                <strong>expertly crafted, nutrient-packed meal plans</strong>{" "}
                designed to{" "}
                <strong>fuel your body and accelerate your results.</strong>
              </p>
              <ul className="mt-4 md:mt-5">
                <li className="flex gap-1 items-baseline mb-1 md:mb-3 font-bold text-xs md:text-base lg:text-lg">
                  <img src={OK} alt="ok" className="w-3 md:w-4" />
                  Effortless progress tracking
                </li>
                <li className="flex gap-1 items-baseline mb-1 md:mb-3 font-bold text-xs md:text-base lg:text-lg">
                  <img src={OK} alt="ok" className="w-3 md:w-4" />
                  Automated meal & macro planning
                </li>
                <li className="flex gap-1 items-baseline mb-1 md:mb-3 font-bold text-xs md:text-base lg:text-lg">
                  <img src={OK} alt="ok" className="w-3 md:w-4" />
                  Science-backed insights for long-term success
                </li>
              </ul>
              <img
                src={avoto}
                className="absolute bottom-8 left-4 sm:left-8 w-6 sm:w-8 md:w-9"
              />
              <img
                src={jrnyAro}
                className="absolute bottom-3 md:bottom-8 max-w-20 md:max-w-full right-3"
              />
            </div>
          </div>

          {/* Third Section */}
          <div
            className={`lg:min-h-[600px] h-full relative flex max-w-[840px] md:w-full  md:mx-auto lg:w-auto lg:mx-0
  text-black bg-[#FCFFF2] rounded-[37px] overflow-hidden ${
    activeSection === "third" ? "active" : ""
  }`}
            onMouseEnter={() => handleMouseEnter("third")}
          >
            <div className="w-[360px] relative img-slide transition-all duration-300 ease-linear hidden lg:block">
              <img
                src={SlideImage3}
                alt="Slider img1"
                className="h-full object-cover"
              />
              <div className="absolute bottom-0 left-0 slidetitle z-10 bg-gradient-to-t from-[#000000bf] to-[#ffffff03] w-full rounded-bl-[38px] rounded-br-[38px] block">
                <div className="text-white px-3 py-10 shrtdesc">
                  <h3 className="text-lg sm:text-xl font-bold mb-2">
                    Your Personal Keto Support...
                  </h3>
                  <p className="text-xs sm:text-[13.4px]">
                    You're never alone on this journey! At KetoBalanced...
                  </p>
                </div>
              </div>
            </div>

            <img
              src={activeSection === "third" ? SlideImage3 : MobileBanner3}
              className={`${
                activeSection === "third"
                  ? "max-w-[140px] md:max-w-[250px] object-cover"
                  : ""
              } lg:hidden w-full`}
            />

            <div className="flex-1 relative p-4 pl-8 px-2 sm:px-4 sm:pl-10 lg:px-10 pt-6 sm:pt-10 lg:pt-14 pb-24 boxslide transition-all duration-300 ease-linear">
              <h3 className="text-lg sm:text-3xl font-bold mb-4 lg:mb-6 relative inline-block">
              <img src={line1} className=" block md:hidden absolute left-0 -bottom-3"/>
                Your Personal Keto Support System
                <img
                  src={avc2}
                  className="absolute w-5 sm:w-6 -left-[22px] sm:-left-[23px] -top-[15px] sm:-top-[15px]"
                />
               
              </h3>
              <p className=" text-xs sm:text-base md:text-lg">
                You're <strong>never alone</strong> on this journey! At{" "}
                <strong>KetoBalanced</strong>, we're with you{" "}
                <strong>every step of the way</strong>-offering expert guidance,
                motivation, and a thriving community to keep you inspired and on
                track.
                <br />
                Join the <strong>KetoBalanced</strong> Movement and start
                transforming your life today!
              </p>
              <ul className="mt-4 md:mt-5">
                <li className="flex gap-1 items-baseline mb-1 md:mb-3 font-bold text-xs md:text-base lg:text-lg">
                  <img src={OK} alt="ok" className="w-3 md:w-4" />
                  Guidance from certified nutritionists
                </li>
                <li className="flex gap-1 items-baseline mb-1 md:mb-3 font-bold text-xs md:text-base lg:text-lg">
                  <img src={OK} alt="ok" className="w-3 md:w-4" />
                  Exclusive community support
                </li>
                <li className="flex gap-1 items-baseline mb-1 md:mb-3 font-bold text-xs md:text-base lg:text-lg">
                  <img src={OK} alt="ok" className="w-3 md:w-4" />
                  Daily tips to keep you on track
                </li>
              </ul>
              <img
                src={avoto}
                className="absolute bottom-8 left-4 sm:left-8 w-6 sm:w-8 md:w-9"
              />
              <img
                src={jrnyAro}
                className="absolute bottom-3 md:bottom-8 max-w-20 md:max-w-full right-3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartJourney;
