import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderTop from "./SliderTop";
// Importing images
import Slider1 from "../../src/assets/images/slider/her-img-1.png";
import Slider2 from "../../src/assets/images/slider/her-img-2.png";
import Slider3 from "../../src/assets/images/slider/her-img-3.png";
import Slider4 from "../../src/assets/images/slider/her-img-4.png";
import Slider5 from "../../src/assets/images/slider/her-img-5.png";
import Slider6 from "../../src/assets/images/slider/her-img-6.png";
import Slider7 from "../../src/assets/images/slider/her-img-7.png";
import Slider8 from "../../src/assets/images/slider/her-img-8.png";
import Slider9 from "../../src/assets/images/slider/her-img-9.png";
import Slider10 from "../../src/assets/images/slider/her-img-10.png";
import Slider11 from "../../src/assets/images/slider/her-img-11.png";
import Slider12 from "../../src/assets/images/slider/her-img-12.png";
import SliderButton from "./SliderButton";

// Array of images
const images = [
  Slider1,
  Slider2,
  Slider3,
  Slider4,
  Slider5,
  Slider6,
  Slider7,
  Slider8,
  Slider9,
  Slider10,
  Slider11,
  Slider12,
  Slider1,
  Slider2,
  Slider3,
  Slider4,
  Slider5,
  Slider6,
  Slider7,
  Slider8,
  Slider9,
  Slider10,
  Slider11,
  Slider12,
];

const BannerSlider = () => {
  const settings = {
    infinite: true,
    className: "center",
    centerMode: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    rtl: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow:4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="flex flex-col">
        <div>
          <SliderTop />
        </div>
        <div className="order-first md:order-last relative">
          <div className="slider-container relative min-h[400px]">
            <span className=" hidden md:block before:content-[''] before:h-[90px] before:w-full before:absolute before:left-0 before:-top-10 lg:before:-top-[1px] before:bg-[#eff4e0] before:z-[1] before:rounded-b-[65%]"></span>
            <Slider {...settings} className="bg-[#eff4e0]">
              {images.map((items, index) => {
                return (
                  <div key={index}>
                    <div className="mx-1 md:mx-2">
                      <img
                        src={items}
                        alt={`slide-img-${index}`}
                        className="slider-image object-cover object-top"
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
            <span className="after:content-[''] after:h-[60px] md:after:h-[90px] after:w-full after:absolute after:left-0 after:bottom-0 after:bg-[#eff4e0] after:z-[1] after:rounded-t-[65%]"></span>
          </div>
        </div>
      </div>

      <SliderButton />
    </>
  );
};

export default BannerSlider;