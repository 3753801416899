import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom';

const Header1 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
    <div  className={`w-full bg-custombgColor py-5 sm:px-24  px-5  flex justify-between border-b-2 border-btntextColor ${
        isScrolled ? "fixed  animate-smooth z-50 shadow-md border-none" : "relative"
      }`}>
      <div><Link to="/"><img src={logo} alt="Logo" className='h-10'/></Link></div>
      
      
    </div>
      
    </>
  )
}

export default Header1
