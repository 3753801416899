import {useEffect} from 'react'

import Header from '../component/Header'
import Footer from '../component/Footer'
import TopFooter from '../component/TopFooter'
import NewsLetter from '../component/NewsLetter'
import Serachlogo from '../assets/images/search.png'
import rightarrow from '../assets/images/rightarrow.png'
import howapp from '../assets/images/howapp.png'
import howweb from '../assets/images/howweb.png'
import arrowImg1 from "../assets/helpcenter/arrow-1.png"
import arrowImg2 from "../assets/helpcenter/arrow-2.png"
import arrowImg3 from "../assets/helpcenter/arrow-3.png"
import marrowImg1 from "../assets/helpcenter/m-arrow-1.png"
import marrowImg2 from "../assets/helpcenter/m-arrow-2.png"
import { Link } from 'react-router-dom'
const HowToUse = () => {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, []);
    return (
        <div className=" bg-custombgColor">
            <Header />
            <div className="w-full  px-4 md:px-14 overflow-hidden">
            <div className="container m-auto mt-10">
                <div className="py-14 flex flex-col md:flex-row justify-between gap-10 items-center">
                    <div className="w-full md:w-1/2 lg:w-1/3 relative">
                        <div className='bordctex flex gap-1'>
                            <Link to="/help-center" >Help center</Link> <img src={rightarrow} alt='rightarrow' className=' h-3 mx-1 my-auto ' />
                            <Link to="/how-to-use" >How to use </Link>
                        </div>
                        <img src={arrowImg1} alt="Arrow" className="hidden md:block absolute -bottom-20 left-20" />
                    </div>
                    <div className="flex items-center justify-center md:justify-end gap-2 max-w-[350px] w-full md:w-2/3 lg:w-1/3 relative">
                                 
                                 <img src={arrowImg2} alt="arrowImg2" className="absolute hidden lg:block -left-[50%]  xl:-left-[40%] -top-5"/>
                    
                                <div className="max-w-[280px] w-full relative z-10">
                                  <input
                                    type="email"
                                    id="username"
                                    placeholder="Search your keyword here..."
                                    className="pl-10 pr-4 py-2 border border-headingColor rounded-[40px] focus:outline-none focus:ring-2 focus:ring-slate-200 placeholder:text-base placeholder:font-normal placeholder:text-[#bebbbb] h-12 w-full"
                                  />             
                                </div>
                                <button className="bg-btntextColor text-white rounded-[40px] focus:outline-none text-lg font-bold w-[48px] h-[48px] mt-2 sm:mt-0 flex justify-center items-center">
                                  <img
                                    src={Serachlogo}
                                    alt="lens Icon"
                                    className="w-1/2 justify-self-center"
                                  />
                                </button>
                              </div>
                </div>
            </div>
            <div className="container m-auto">
                <div className="py-12 px-6 md:px-0 mb-20 relative">
                    <img src={arrowImg3} alt="arrowImg3" className="hidden md:block absolute -right-[60px] -top-[25px]" />
                    <img src={marrowImg1} alt="arrowImg3" className="block md:hidden absolute top-0 -left-3" />
                    <img src={marrowImg2} alt="arrowImg3" className="block md:hidden absolute -top-3 -right-6" />
                    <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 rounded-lg text-center">
                        <div className="rounded-3xl bg-[#FCFFF2] p-8 lg:p-12">
                            <Link to="/how-use-app">
                                <img src={howapp}className="  m-auto w-[60px] h-[60px]"/>
                                <h6 className="text-xl font-extrabold py-3">How to use App</h6>
                                <p className="text-base sm:text-lg 2xl:text-2xl">
                                    Wondering how to use our App? We're here to guide you!
                                </p></Link>
                        </div>
                        <div className="rounded-3xl bg-[#FCFFF2] p-8 lg:p-12">
                            <Link to="/how-use-web"> 
                            <img src={howweb} className=" m-auto w-[60px] h-[60px]" />
                                <h6 className="text-xl font-extrabold py-3">How to use Web</h6>
                                <p className="text-base sm:text-lg 2xl:text-2xl">
                                    Wondering how to use the website? We're here to guide you!
                                </p></Link>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <NewsLetter />
            <TopFooter />
            <Footer />
        </div>
    )
}

export default HowToUse
