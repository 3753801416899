import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Privacy from './pages/Privacy';
import TrustedSources from './pages/TrustedSources';
import Terms from './pages/Terms';
import Contact from './pages/Contact'
import Home from './pages/Home';
import HelpCenter from './pages/HelpCenter';
import AboutUs from './pages/AboutUs';
import CookiePolicy from './pages/CookiePolicy'
import General from './pages/General'
import Subscription from './pages/Subscription';
import Paymentinfo from './pages/Paymentinfo'
import HowToUse from './pages/HowToUse'
import WhyKeto from './pages/WhyKeto'
import HowUseApp from './pages/HowUseApp';
import HowUseWeb from './pages/HowUseWeb';
import Lp from './pages/Lp';
import Blog from './pages/Blog';


function App() {

   return (
    <div className="App">
     <Router>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/help-center" element={<HelpCenter />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/trusted-sources" element={<TrustedSources />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route path="/general" element={<General />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/paymentinfo" element={<Paymentinfo />} />
          <Route path="/how-to-use" element={<HowToUse />} />
          <Route path="/why-keto-diet" element={<WhyKeto />} />
          <Route path="/how-use-app" element={<HowUseApp />} />
          <Route path="/how-use-web" element={<HowUseWeb />} />
          <Route path="/lp" element={<Lp />} />
          <Route path="/blog" element={<Blog />} />
          
        </Routes>
      
    </Router>
    
    </div>
  );
}

export default App;
