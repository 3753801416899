import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import arrow2 from "../assets/images/right-arrow.svg";
import arrow1 from "../assets/images/left-arrow.svg";
import slide from "../assets/images/slide-1.svg";
import slide2 from "../assets/images/slide-2.svg";
import slide3 from "../assets/images/slide-3.svg";
import slide4 from "../assets/images/slide-4.svg";
import item1 from "../assets/images/item-1.png";
import item2 from "../assets/images/item-2.png";
import curv1 from "../assets/images/arrow-curv.png";
import curv2 from "../assets/images/arrow-curv-2.png";
import curvtop from "../assets/images/curce-top.png";
import curvtop2 from "../assets/images/curce-top2.png";
import curvtop3 from "../assets/images/curce-top3.png";
import curvbtom from "../assets/images/curv-bottm.png";
import curvbtom1 from "../assets/images/curv-bottm-1.png";
import MobAr1 from "../assets/images/arrow/mob-ar1.png";
import MobAr2 from "../assets/images/arrow/mob-ar2.png";
import GetDream from "../assets/images/get.png";
import Loose from "../assets/images/loose.png";
import Fuel from "../assets/images/fuel.png";
import Every from "../assets/images/every.png";
import Success from "../assets/images/success.png";
import Effort from "../assets/images/effort.png";
import Empower from "../assets/images/empower.png";
import Focus from "../assets/images/focus.png";
export default function Slider() {
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);
  const handlePrevClick = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const nevEl = (
    <div className="wrpvsible absolute left-[3%] md:left-[7%] lg:left-24 bottom-10 lg:bottom-14">
      <button
        onClick={handlePrevClick}
        className={`custom-prev-btn ${
          isStart ? "opacity-50 cursor-not-allowed" : "opacity-100"
        }`}
        disabled={isStart}
      >
        <img src={arrow1} alt="left-arrow" />
      </button>
      <button
        onClick={handleNextClick}
        className={`custom-next-btn ${
          isEnd ? "opacity-50 cursor-not-allowed" : "opacity-100"
        }`}
        disabled={isEnd}
      >
        <img src={arrow2} alt="right-arrow" />
      </button>
    </div>
  );

  return (
    <>
      <div className="max-w-[840px] 2xl:max-w-[955px] m-auto relative">
        <div className="flex md:hidden">
          <img src={MobAr2} alt="Arrow" className="w-30" />
        </div>
        <h2 className="relative design-sec mb-3 md:mb-7 text-left sm:text-center font-extrabold text-4xl leading-10 md:text-[64px] sm:text-5xl 2xl:text-7xl md:leading-[80px] 2xl:leading-[98px]">
          Designed for Keto Beginners - The Smart Way to Keto
          <div className="flex md:hidden justify-end -mt-6 -mb-2 md:mb-10">
            <img src={MobAr1} alt="Arrow" className="w-28 mt-6 sm:mt-6" />
          </div>
        </h2>
        <p className="mb-10 font-normal text-base sm:text-lg 2xl:text-2xl max-w-[840px] m-auto text-left sm:text-center">
          Where others feel lost,{" "}
          <strong>we guide you every step of the way</strong>. At{" "}
          <strong>KetoBalanced</strong>, you'll find{" "}
          <strong>everything you need</strong> to start keto{" "}
          <strong>the right way</strong>-with clarity, confidence, and ease.
        </p>
      </div>
      <div className="w-full lg:container m-auto">
        <Swiper
          spaceBetween={10}
          centeredSlides={true}
          slidesPerView={"auto"}
          grabCursor={true}
          onSlideChange={(swiper) => {
            setIsStart(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
          autoplay={{
            delay: 300000,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Autoplay]}
          className="mySwiper"
          ref={swiperRef}
        >
          <SwiperSlide className="nextSlide bg-[#265F58] relative px-2 md:px-3 xl:px-6 pt-10 overflow-hidden">
            <div className="wrpvsible">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 px-5 md:px-7 xl:px-16 2xl:px-20 md:pt-10 pt-0 relative">
                <div className="relative">
                  <h3 className="uppercase text-white text-base">Step 1</h3>
                  <h3 className="pt-10 md:pt-0 text-4xl lg:text-[2.2rem] text-white font-bold my-4 md:my-6">
                    Take a quiz
                  </h3>
                  <p className="text-base text-white">
                    Tell us what your goals are, what and how you like to eat.
                  </p>
                  <img
                    src={item1}
                    alt="item-img"
                    className="absolute left-[30%] lg:left-1/2 top-[5%] md:-top-[50%] lg:-top-[12%]"
                  />
                  <img
                    src={item2}
                    alt="item-img"
                    className="hidden md:block absolute right-0 bottom-[-50%] lg:bottom-10"
                  />
                </div>
                <div className="relative">
                  <img src={slide} alt="slide-img" className="ml-auto" />
                  <img
                    src={curv2}
                    alt="item-img"
                    className="hidden md:block absolute lg:left-[5%] 2xl:left-[35%] bottom-[45%] lg:bottom-1/3"
                  />
                  <span className="hidden md:block text-lg absolute  lg:left-[5%] 2xl:left-[35%] bottom-[30%] lg:bottom-[20%] text-white text-center origin-center -rotate-12 font-mono caveat">
                    Lose weight in <br /> a Keto way
                  </span>
                </div>
                <img
                  src={GetDream}
                  alt="arrow"
                  className="block md:hidden absolute w-16 -right-1 top-[5%] "
                />
                <img
                  src={Loose}
                  alt="arrow"
                  className="hidden xs:block sm:block md:hidden absolute w-16 left-[6%] top-[45%] "
                />

                <img
                  src={curv1}
                  alt="arrow"
                  className="hidden md:block absolute xl:top-[2%] 2xl:top-[10%] lg:left-[40%] left-[65%] top-[5%] md:w-44 lg:w-56 "
                />
                <span className="hidden md:block text-lg absolute lg:top-[-6%] caveat xl:top-[-6%] 2xl:top-[0%] lg:left-[45%] left-[75%] -top-[2%] text-white text-center origin-center -rotate-12 font-mono">
                  Get your <br /> dream shape
                </span>
              </div>
            </div>

            {nevEl}
          </SwiperSlide>

          <SwiperSlide className="nextSlide bg-[#F2606A] relative px-2 md:px-3 xl:px-6 pt-10 overflow-hidden">
            <div className="wrpvsible">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 px-0 sm:px-5 md:px-7 xl:px-16 2xl:px-20 md:pt-10 pt-0 relative">
                <div className="relative">
                  <h3 className="uppercase text-white text-base">Step 2</h3>
                  <h3 className="text-[30px] sm:text-4xl lg:text-[2.2rem] text-white font-bold my-4 md:my-6 pt-10 md:pt-0">
                    Get a <br /> personalized plan
                  </h3>
                  <p className="text-base text-white">
                    We will create a nutrition plan that's perfect for your
                    goals, body, and needs.
                  </p>
                  <img
                    src={item1}
                    alt="item-img"
                    className="absolute left-[30%] lg:left-1/2 top-[5%] md:-top-[50%] lg:-top-[12%]"
                  />
                  <img
                    src={item2}
                    alt="item-img"
                    className="hidden md:block absolute right-0 bottom-[-40%] lg:bottom-10"
                  />
                </div>
                <div className="relative">
                  <img src={slide2} alt="slide-img" className="ml-auto" />
                  <img
                    src={curv2}
                    alt="item-img"
                    className="hidden md:block absolute lg:left-[5%] 2xl:left-[20%] bottom-[45%] lg:bottom-1/4"
                  />
                  <span className="hidden md:block text-lg absolute  lg:left-[5%] 2xl:left-[20%] bottom-[33%] lg:bottom-[14%] text-white text-center origin-center -rotate-12 caveat">
                    Every step <br /> leads to success
                  </span>
                </div>
                <img
                  src={Fuel}
                  alt="arrow"
                  className="block md:hidden absolute w-16 left-[68%] top-[5%] "
                />
                <img
                  src={Every}
                  alt="arrow"
                  className="hidden xs:block sm:block md:hidden absolute w-16 left-[6%] top-[51%] "
                />

                <img
                  src={curvtop}
                  alt="arrow"
                  className="hidden md:block absolute left-[65%] lg:left-[55%] top-[5%] lg:top-[10%] xl:top-[10%] 2xl:top-[10%]  "
                />
                <span className="hidden md:block text-lg absolute lg:top-[-6%]  xl:top-[-6%] 2xl:top-[0%] left-[65%] lg:left-[55%] -top-[2%] text-white text-center origin-center -rotate-12 caveat">
                  Fuel success <br /> with keto power.
                </span>
              </div>
            </div>
            {nevEl}
          </SwiperSlide>

          <SwiperSlide className="nextSlide bg-[#054453] relative px-2 md:px-3 xl:px-6 pt-10 overflow-hidden">
            <div className="wrpvsible">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 px-5 md:px-7 xl:px-16 2xl:px-20  relative md:pt-10 pt-0">
                <div className="relative">
                  <h3 className="uppercase text-white text-base">Step 3</h3>
                  <h3 className="text-4xl lg:text-[2.2rem] text-white font-bold my-4 md:my-6 pt-10 md:pt-0">
                    Let us guide you
                  </h3>
                  <p className="text-base text-white">
                    Get step-by-step daily recommendations on how to lose weight
                    for good.
                  </p>
                  <img
                    src={item1}
                    alt="item-img"
                    className="absolute left-[30%] lg:left-1/2 top-[5%] md:-top-[50%] lg:-top-[12%]"
                  />
                  <img
                    src={item2}
                    alt="item-img"
                    className="hidden md:block absolute right-0 bottom-[-55%] lg:bottom-10"
                  />
                </div>
                <div className="relative">
                  <img src={slide3} alt="slide-img" className="ml-auto" />
                  <img
                    src={curvbtom}
                    alt="item-img"
                    className="hidden md:block -rotate-12 absolute left-[5%] bottom-[45%] lg:-left-[40px] lg:bottom-[40%]"
                  />
                  <span className="hidden md:block text-lg absolute  left-[15%]  bottom-[33%] lg:-left-[5px] lg:bottom-[25%] text-white text-center origin-center -rotate-12 caveat">
                    Your effort fuels <br /> your results
                  </span>
                </div>
                <img
                  src={Success}
                  alt="arrow"
                  className="block md:hidden absolute w-20 right-2 -top-[5%] "
                />
                <img
                  src={Effort}
                  alt="arrow"
                  className="hidden xs:block sm:block md:hidden absolute w-20 left-[6%] top-[50%] "
                />

                <img
                  src={curvtop2}
                  alt="arrow"
                  className="hidden md:block absolute left-[65%] lg:left-[50%] top-[5%] lg:top-[10%] xl:top-[5%] 2xl:top-[10%]  "
                />
                <span className="hidden md:block text-lg absolute lg:top-[-6%]  xl:top-[-6%] 2xl:top-[0%] left-[65%] lg:left-[50%] -top-[2%] text-white text-center origin-center -rotate-12 caveat">
                  Success tastes <br /> better than carbs
                </span>
              </div>
            </div>

            {nevEl}
          </SwiperSlide>

          <SwiperSlide className="nextSlide bg-[#FF7C0A] relative px-2 md:px-3 xl:px-6 pt-10 overflow-hidden">
            <div className="wrpvsible">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 px-5 md:px-7 xl:px-16 2xl:px-20 md:pt-10 pt-0 relative">
                <div className="relative">
                  <h3 className="uppercase text-white text-base">Step 4</h3>
                  <h3 className="text-4xl lg:text-[2.2rem] text-white font-bold my-4 md:my-6 pt-10 md:pt-0">
                    Reach your goals
                  </h3>
                  <p className="text-base text-white">
                    Achieve the health and body you want. Enjoy the new you!
                  </p>
                  <img
                    src={item1}
                    alt="item-img"
                    className="absolute left-[30%] lg:left-1/2 top-[5%] md:-top-[50%] lg:-top-[12%]"
                  />
                  <img
                    src={item2}
                    alt="item-img"
                    className="hidden md:block absolute right-0 bottom-[-55%] lg:bottom-10"
                  />
                </div>
                <div className="relative">
                  <img src={slide4} alt="slide-img" className="ml-auto" />
                  <img
                    src={curvbtom1}
                    alt="item-img"
                    className="hidden md:block absolute left-0 bottom-[65%] lg:bottom-[42%] lg:-left-[60px]"
                  />
                  <span className="hidden md:block text-lg absolute left-[5%]  bottom-[57%] lg:-left-[20px]  lg:bottom-[32%] text-white text-center  origin-center -rotate-12 caveat">
                    Focus, fuel, <br /> and thrive
                  </span>
                </div>
                <img
                  src={Empower}
                  alt="arrow"
                  className="block md:hidden absolute w-24 -right-2 -top-[2%] "
                />
                <img
                  src={Focus}
                  alt="arrow"
                  className="hidden xs:block sm:block md:hidden absolute w-20 left-[6%] top-[50%] "
                />
                <img
                  src={curvtop3}
                  alt="arrow"
                  className="hidden md:block absolute left-[65%] lg:left-[50%] top-[5%] lg:top-[10%] xl:top-[5%] 2xl:top-[10%] md:w-40 lg:w-52"
                />
                <span className="hidden md:block text-lg absolute lg:top-[-6%]  xl:top-[-6%] 2xl:top-[0%] left-[65%] lg:left-[53%] -top-[2%] text-white text-center origin-center -rotate-12 caveat">
                  Empower your <br /> life with keto
                </span>
              </div>
            </div>

            {nevEl}
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
