import React from "react";
import PhoneBanner from "../assets/images/phonebanner.png";
import MobBanner from "../assets/images/mob-banner.png";
import Arrow3 from "../assets/images/arrow/arrow3.svg";
import Arrow4 from "../assets/images/arrow/arrow4.svg";
import altimatearw from "../assets/images/altimate-arrow.png";
const DietJourney = () => {
  return (
    <>
      <div className="py-24 text-center overflow-hidden">
        <h2 className="relative px-4 md:px-0 text-4xl md:text-5xl lg:text-[56px] xl:text-[60px] font-extrabold text-headingColor leading-tight inline-block">
         
         <img src={altimatearw} className="absolute -top-20 block md:hidden"/>
         
          <div className="absolute hidden -left-36 md:-left-24 lg:-left-36 md:flex justify-center items-center flex-col -top-10 lg:top-0">            
            <img src={Arrow3} alt="Arrow" className="w-28 lg:w-32" />
          </div>
          The Ultimate App for Your  <br/> Keto Journey
          <div className="absolute hidden -right-52 md:-right-32 lg:-right-44 md:flex justify-center items-center flex-col md:top-9 lg:top-0">
            
            <img src={Arrow4} alt="Arrow" className="w-28 lg:w-30" />
          </div>
        </h2>
        <p className="relative text-base sm:text-lg 2xl:text-2xl font-normal mt-8">
        The <strong>only keto app you'll ever need</strong>-designed to <strong>simplify, guide, and transform</strong> your diet journey effortles</p>
        <div className="text-center pt-5 sm:pt-24">
          <img
            src={PhoneBanner}
            alt="Logo"
            className="w-full m-auto hidden sm:block"
          />
          <img
            src={MobBanner}
            alt="Logo"
            className="max-w-[280px] w-full m-auto sm:hidden block"
          />
        </div>
      </div>
    </>
  );
};

export default DietJourney;
