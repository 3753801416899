import {useEffect} from 'react'
import Header1 from '../component/Header1'
import Footer from '../component/Footer'
const TrustedSources = () => {
  useEffect(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, []);
  return (
    <div className='bg-custombgColor'>
      <Header1 />
      <div className='px-5 md:px-0 md:mx-24 sm:mx-12'>
        <div className="grid grid-cols-12 gap-5 mb-20 mt-20">
          {/* Main Content */}
          <div className="col-span-12 md:col-span-9 order-last md:order-first">
            <h1 className="text-5xl sm:text-5xl md:text-6xl lg:text-[64px] mb-7 font-extrabold"> KetoBalanced Trusted Sources & Experts</h1>
            
            
          
            <h3 className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 mb-3" id="trusted-sources">Our Trusted Sources </h3>
            <div>
            <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://diabetesjournals.org/care/issue/44/Supplement_1">2021 Standards of Medical Care in Diabetes</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.eatright.org/">Academy of Nutrition and Dietetics</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.aad.org/">American Academy of Dermatology Association</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.aafp.org/home.html">American Academy of Family Physicians</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.acsm.org/">American Academy of Sleep Medicine</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.acog.org/">American College of Obstetricians and Gynaecologists</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.acsm.org/">American College of Sports Medicine</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.acefitness.org/">American Council on Exercise</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.diabetes.org/">American Diabetes Association</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.aafp.org/journals/afp.html">American Family Physician</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.aicr.org/">American Institute for Cancer Research</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://academic.oup.com/ajcn">American Journal of Clinical Nutrition</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://theana.org/">American Nutrition Association</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.apa.org/topics">American Psychological Association</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://nutrition.org/">American Society for Nutrition</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.asrm.org/?vs=1">American Society for Reproductive Medicine</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.health.gov.au/">Australian Department of Health</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.acsm.org/docs/default-source/publications-files/acsm-guidelines-download-10th-edabf32a97415a400e9b3be594a6cd7fbf.pdf">Benefits and Risks Associated with Physical Activity (ACSM)</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://youtu.be/_PLD5RLLvfQ">Big Think</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://journals.bmj.com/home">BMJ</a><br />
          <a  className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.cdc.gov/">Centers for Disease Control and Prevention</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://clinicaltrials.gov/ct2/home">ClinicalTrials.gov</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.cochranelibrary.com/">Cochrane Library</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://academic.oup.com/cdn">Current Developments in Nutrition</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="http://guidelines.diabetes.ca/">Diabetes Canada</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.diabetes.org.uk/">Diabetes UK</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.dietaryguidelines.gov/">Dietary Guidelines for Americans</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.dietaryguidelines.gov/sites/default/files/2021-03/Dietary_Guidelines_for_Americans-2020-2025.pdf">Dietary Guidelines for Americans 2020-2025</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.nal.usda.gov/sites/default/files/fnic_uploads/energy_full_report.pdf">DRI DIETARY REFERENCE INTAKES FOR Energy, Carbohydrate, Fiber, Fat, Fatty Acids, Cholesterol, Protein, and Amino Acids</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.duodecim.fi/english/">Duodecim</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.elsevier.com/open-access/open-access-journals">Elsevier</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.ebcog.eu/publications">European Board &amp; Colle</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.eshre.eu/Guidelines-and-Legal">European Society of Human Reproduction and Embryology</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.everydayhealth.com/">Everyday Health</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://diabetes-resources-production.s3.eu-west-1.amazonaws.com/resources-s3/2018-03/1373_Nutrition%20guidelines_0.pdf">Evidence-based nutrition guidelines for the prevention and management of diabetes March 2018</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://academic.oup.com/emph/issue/9/1">Evolution Medicine &amp; Public Health</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.fsrh.org/home/">Faculty of the Royal College of the Obstetricians and Gynaecologists</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.aafp.org/journals/fpm.html">Family Practice Management</a><br />
          <a href="https://www.fertstert.org/">Fertility and Sterility</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://fertilitycare.org/">Fertility Care Centers of America</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.fda.gov/">Food and Drug Administration</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://gco.iarc.fr/">Global Cancer Observatory</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.glowm.com/">Global Library of Womens Medicine</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.acsm.org/docs/default-source/publications-files/acsm-guidelines-download-10th-edabf32a97415a400e9b3be594a6cd7fbf.pdf?sfvrsn=aaa6d2b2_0%27">Guidelines for Exercise Testing</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.guttmacher.org/">Guttmacher Institute</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.health.harvard.edu/">Harvard Health Publishing</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://health.gov/">Health.gov</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.healthline.com/">Healthline</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.heart.org/">Heart.org</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://podcasts.apple.com/ua/podcast/huberman-lab/id1545953110">Huberman Lab</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://academic.oup.com/humrep">Human Reproduction</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://irh.org/">Institute for Reproductive Health</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.iarc.who.int/">International Agency for Research on Cancer</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.figo.org/">International Federation of Gynecology and Obstetrics</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}}  href="https://www.isswsh.org/">International Society for the Study of Women's Sexual Health</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://jamanetwork.com/">JAMA</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.mayoclinic.org/">Mayo Clinic</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://medlineplus.gov/genetics/">MedLinePlus</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.medscape.com/">MedScape</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.medscape.com/womenshealth">Medscape Ob/Gyn &amp; Women's Health</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.menshealth.com/">Men's Health</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://nam.edu/">National Academy of Medicine</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.cancer.gov/">National Cancer Institute</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.ncbi.nlm.nih.gov/">National Center for Biotechnology Information</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.nlm.nih.gov/">National Health Library</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.nhs.uk/">National Health Service</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.nichd.nih.gov/ncmhep">National Institute of Child Health and Human Development</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.nice.org.uk/">National Institute for Health and Care Excellence</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.nsca.com/">National Strength and Conditioning Association</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://natural-fertility-info.com/">Natural Fertility</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://naturalwomanhood.org/">Natural Womanhood</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://newsinhealth.nih.gov/">News in Health</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.womenshealth.gov/">Office on Women's Health</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://educators.osmosis.org/medical/?_ga=2.208609170.1696575100.1638783542-931813442.1638783542">Osmosis</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://health.gov/sites/default/files/2019-09/Physical_Activity_Guidelines_2nd_edition.pdf">Physical Activity Guidelines for Americans, 2nd edition (2019)</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.pcrm.org/">Physicians Committee for Responsible Medicine</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://pubmed.ncbi.nlm.nih.gov/">PubMed</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://raf.bioscientifica.com/">Reproduction &amp; Fertility</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.reproductivefacts.org/">Reproductive Facts</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.rcog.org.uk/">Royal College of Obstetricians and Gynaecologists</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.runnersworld.com/">Runner's World</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}}  href="https://www.sciencedirect.com/">Science Direct</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.scopus.com/home.uri">Scopus</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.sleep.org/">Sleep.org</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.smfm.org/">Society for Maternal Fetal Medicine</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://srf-reproduction.org/">Society for Reproduction and Fertility</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://docs.google.com/document/d/1F-PmTYTCCRGAm6tBKe53MqjTIBWQu0vt1zAcm4ROCEk/edit">Sources of vitamins and minerals statement</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://link.springer.com/">Springer Link</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.youtube.com/watch?v=B9RruLkAUm8">TEDx Talks</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.jsm.jsexmed.org/">The Journal Of Sexual Medicine</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="http://www.nwcr.ws/">The National Weight Control Registry</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.nejm.org/">The New England Journal of Medicine</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.sugar.org/about/">The Sugar Association</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.uptodate.com/contents/search">UpToDate</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.verywellfit.com/">Verywellfit</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.webmd.com/">WebMD</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://academic.oup.com/emph/issue/9/1">Wiley's Obstetrics and Gynaecology hub</a><br />
          <a className="text-base sm:text-lg 2xl:text-2xl hover:underline" style={{lineHeight:"1.75"}} href="https://www.who.int/">World Health Organization</a>
            </div>
           
            <h3 className="font-bold text-[32px] pt-5 md:pt-6 2xl:pt-10 mb-3" id="our-experts">Our Experts</h3>
          
            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.75"}}> <strong>Sandhya Bhat</strong> — PhD & Certified dietitian-nutritionist, Registered Dietitian, Registered Dietitian Nutritionist, Certified Diabetes Care and Education Specialist</p>
            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.75"}}> <strong>Yatika Bajaj</strong> — Certified dietitian-nutritionist, Registered Dietitian, Registered Dietitian Nutritionist, Certified Nutrition Support Clinician, Certified Specialist in Sports Dietetics</p>
            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.75"}}> <strong>Ruchita</strong> —  Registered Dietitian Nutritionist, Certified Diabetes Care and Education Specialist</p>
            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.75"}}> <strong>Raveena</strong> — Registered Dietitian Nutritionist, Certified Dietitian/Nutritionist, Certified Case Manager, Certified Diabetes Care and Education Specialist, Certified Personal Trainer, Fitness Specialist</p>
            <p className="text-base sm:text-lg 2xl:text-2xl" style={{lineHeight:"1.75"}}> <strong>Nimisha</strong> — Registered Dietitian-nutritionist, Licensed Dietitian Nutritionist, Certified Personal Trainer </p>
          
         
          </div>
          {/* Sidebar */}
          <div className="col-span-12 md:col-span-3 leftboox md:sticky top-24  min-h-fit md:h-[475px] rounded-3xl">
            <div className="px-5 py-4 md:px-6 md:py-8">
              <ol className='litex space-y-2 pl-4'>
                <li className='mb-3' style={{lineHeight:"1.75"}}><a href="#trusted-sources" className=" hover:underline">Our Trusted Sources</a></li>
                <li className='mb-3' style={{lineHeight:"1.75"}}><a href="#our-experts" className=" hover:underline">Our Experts</a></li>
                
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TrustedSources
