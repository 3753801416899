import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
    <div  className={`w-full bg-custombgColor py-5 sm:px-24  px-5  flex justify-between border-b-2 border-btntextColor ${
        isScrolled ? "fixed  animate-smooth z-50 shadow-md border-none" : "relative"
      }`}>
      <div><Link to="/"><img src={logo} alt="Logo" className='h-10'/></Link></div>
      <div className='md:flex gap-5 hidden'>
        <Link to="https://user.ketobalanced.com/login">
        <button className='bg-btngreenColor text-btntextColor rounded-lg focus:outline-none text-sm font-bold w-52 h-10'>Already a member</button>
        </Link>
        <Link to="https://usa.ketobalanced.com/">
        <button className='bg-btnorangeColor text-white rounded-lg focus:outline-none text-sm font-bold w-52 h-10'>Begin your journey</button>
        </Link>
      </div>
      <div className='flex gap-5 md:hidden'>
      <button
        onClick={toggleMenu}
        className="text-white p-2   focus:outline-none md:hidden"
      >
        {/* Menu Icon */}
        {isMenuOpen ? (
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 18.5L6.5 7M19 7L6.5 18.5" stroke="#417421" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          
        ) : (
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.5 12.5H3.5M19.5 7.5H3.5M19.5 17.5H11.5" stroke="#417421" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        )}
      </button>

      {/* Menu Buttons */}
      <div className={`border-t-2 border-btntextColor w-full h-[100vh] flex gap-2 top-[80px]  absolute p-4 z-50 text-right  sm:px-24 flex-col right-0 bg-custombgColor ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
      <Link to="https://user.ketobalanced.com/login"><button className="bg-btngreenColor text-btntextColor rounded-lg focus:outline-none text-sm font-bold w-52 h-10">
          Already a member
        </button></Link>
        <Link to="https://usa.ketobalanced.com/"> <button className="bg-btnorangeColor text-white rounded-lg focus:outline-none text-sm font-bold w-52 h-10">
          Begin your journey
        </button></Link>
      </div>
      </div>
    </div>
      
    </>
  )
}

export default Header
