import React from "react";
import { Link } from "react-router-dom";
const SliderButton = () => {
  return (
    <>
      <div className="sm:px-24  px-5 bg-custombgColor pb-44 ">
        <div className="w-[300px] sm:w-96 border-2 border-dashed border-btnorangeColor m-auto rounded-[50px] text-center  p-2 mt-[-20px] z-40 relative slidebtnaro">
          
          <Link
            to="https://usa.ketobalanced.com/"
            className="border-[3px] border-solid border-white rounded-[31px] bg-btnorangeColor font-bold text-xs sm:text-lg text-white flex items-center justify-center py-4 px-2 sm:px-7 shadow-[inset_0px_-6.25px_6.25px_0px_#00000033,inset_0px_6.25px_6.25px_0px_#FFFFFF33]"
          >
            Start Your Transformation Today!
          </Link>
        </div>
      </div>
    </>
  );
};

export default SliderButton;
