import React from "react";
import WeightLossGoal from "../assets/images/weightlossgoal.svg";
import Arrow3 from "../assets/images/arrow/arrow6.png";
import Arrow14 from "../assets/images/arrow/arrow14.png";
import mobileWlose from "../assets/images/m-w-lose-1.png";
import mobileWlose2 from "../assets/images/m-w-lose-2.png";
import { Link } from "react-router-dom";

const WeightlossGoal = () => {
  return (
    <>
      <div className="px-4 py-20 pb-5 md:py-24">
        <div className="container m-auto  ">
          <div className="flex justify-between items-center gap-10 md:gap-20 md:flex-row flex-col">
            <div className="max-w-md w-full relative">
              <img
                src={WeightLossGoal}
                alt="Weight Loss Goal"
                className="w-full"
              />
              <img
                src={mobileWlose}
                className="absolute bottom-0 right-0 block md:hidden"
              />
              <img
                src={mobileWlose2}
                className="absolute bottom-0 left-0 block md:hidden"
              />
            </div>

            <div className="relative py-2 md:py-14">
              <div
                className="absolute -left-28 hidden md:flex justify-center items-center flex-col -top-20 xl-top-28 mt-10"
                style={{ transform: "rotate(45deg)" }}
              >
                <span
                  className="absolute -ms-20 caveat text-lg -top-0"
                  style={{ transform: "rotate(-32deg)" }}
                >
                  Know your goals
                </span>
                <img src={Arrow3} alt="Arrow" className="w-32" />
              </div>
              <h2 className="text-4xl sm:text-[48px] font-extrabold text-headingColor leading-tight">
                See How Fast You Can Reach Your{" "}
                <span className="text-[#417421] md:text-headingColor">
                  Weight Loss Goals!
                </span>
              </h2>
              <Link to="https://usa.ketobalanced.com/">
                <button className="bg-btnorangeColor text-white rounded-[40px] focus:outline-none text-xl font-bold max-w-full sm:max-w-52 w-full h-12 mt-12">
                  Take a quiz
                </button>
              </Link>
              <div className="absolute -left-28 hidden md:flex justify-center items-center flex-col bottom-0">
                <img src={Arrow14} alt="Arrow" className="w-30" />
                <span className="absolute -ms-4  text-lg -bottom-6 rotate-6 caveat">
                  Lose weight
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeightlossGoal;
