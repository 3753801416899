import {useEffect} from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import UnderstandingKeto from '../component/UnderstandingKeto'
import BottomWrap from '../component/BottomWrap'
import BannerSlider from '../component/BannerSlider'
import LossWeight from '../component/LossWeight'
import SliderWrap from '../component/SliderWrap'
const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header />
      <BannerSlider/>
      <LossWeight />
      <SliderWrap />
      <UnderstandingKeto />
      <BottomWrap />
      
      <Footer />
    </>
  )
}

export default Home
