import React from 'react'
import Avocadoblink from '../../src/assets/images/avocado-blink.webp'
import Avo3 from '../../src/assets/images/avo-2.png'
import Avo2 from "../../src/assets/images/avc-img.png";
import arrowtl from "../../src/assets/images/title-arrow.png";
import arrowtl2 from "../../src/assets/images/title-arrow-2.png";
import arrowtl3 from "../../src/assets/images/title-arrow-3.png";

const SliderTop = () => {
  return (
    <>
     <div className='overflow-hidden w-full bg-custombgColor pt-0 md:pt-12 lg:pt-16 2xl:pt-24 pb-20 md:pb-5 2xl:pb-6 relative z-20'>
      <div className='container m-auto'>
      <div className="flex flex-col gap-3 md:flex-row items-center justify-center relative">
        <img src={arrowtl2} className='block md:hidden relative -left-[12%]'/>

        <h1 className="text-4xl sm:leading-[58px] md:text-[64px] sm:text-5xl 2xl:text-7xl text-headingColor md:leading-[80px] 2xl:leading-[98px] text-center font-extrabold relative">
            <span className="relative avocadoblink">
            Take Charge of Your Health 
              
            </span>
            <br/>
            <span className="relative inline bficn">
            The Keto Way!               
                    
            </span>           
        </h1>
         
          <img src={Avocadoblink} className='md:hidden inline max-w-[70px] rotate-[25deg]'/>
          <img src={arrowtl3} className='md:hidden block absolute z-50 -bottom-[20%] right-[5%] sm:right-1/4'/>
        
      </div>
      </div>    
     </div>
    </>
  )
}

export default SliderTop
