import React from "react";
import phonebanner from "../assets/aboutimage/phone-oval-bg.png";
import headingdivider from "../assets/aboutimage/heading-divider.png";
import Avocadoblink from "../assets/aboutimage/avocado-blink.webp";
import arroeline2 from "../assets/aboutimage/arrow-line-2.png";
import arroeline1 from "../assets/aboutimage/arrow-line-1.png";
import marroeline1 from "../assets/aboutimage/m-arrow-line-1.png";
import marroeline2 from "../assets/aboutimage/m-arrow-line-2.png";

const AboutSection_1 = () => {
  return (
    <div className="w-full bg-custombgColor px-4 md:px-0 pt-16 pb-10 md:pb-28 overflow-hidden">
      <div className="w-full m-auto">
        <div className="px-4 lg:pl-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 place-items-center gap-8 md:gap-12">
            {/* Text Section */}
            <div className="max-w-[750px] mx-auto text-center md:text-left content-center  relative">
              <img
                src={Avocadoblink}
                alt="icon-img"
                className="mb-4 hidden md:block w-16"
              />
              <img
                src={arroeline1}
                alt="arrow-img-2"
                className="absolute right-0 top-0 hidden md:block"
              />

              <h2 className="mb-1 text-center md:text-left font-extrabold text-4xl md:text-[60px] sm:text-5xl 2xl:text-7xl" style={{lineHeight:"1.1"}}>
                Where Health Meets Flavor, That's
                <span className="text-[#417421]"> KetoBalanced</span>
              </h2>

              <img
                src={headingdivider}
                className="w-2/3 m-auto lg:ml-0"
                alt="Heading Divider"
              />
              <p className="text-base sm:text-lg 2xl:text-2xl font-normal mt-8">
                A lifestyle revolution blending health and flavor with
                keto-friendly meals, snacks, and expert resources.
              </p>

              <img
                src={arroeline2}
                alt="arrow-img-2"
                className="absolute right-0 lg:-right-20 -bottom-16 lg:-bottom-28 hidden md:block"
              />
            </div>

            {/* Image Section */}
            <div className="relative">
              <div className="flex justify-between items-center md:hidden relative top-7">
                <img src={marroeline1} alt="mobile-arrow-1" />
                <img src={marroeline2} alt="mobile-arrow-2" />
              </div>
              <img
                src={phonebanner}
                alt="Phone Banner"
                className="md:max-w-[700px] w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection_1;
