import React from "react";
import KetoBalanced from "../assets/images/ketobalanced.svg";
import loseWeight from "../assets/images/lose-weight.png";
import loseWeight2 from "../assets/images/lose-weight-2.png";
import loserose from "../assets/images/lose-rose.png";
import Leaf2 from "../assets/images/arrow/leaf2.png";
import { Link } from "react-router-dom";

const LossWeight = () => {
  return (
    <>
      <div className="w-full py-10 sm:py-14 md:py-24 px-4 sm:px-24 rounded-[60px] rounded-b-none md:rounded-t-[20%] bg-btngreenColor mt-[-120px]">
        <div className="container m-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div className="order-2 md:order-1">
              <div className="relative">
              <h3 className="text-2xl sm:text-[40px] font-bold leading-8 sm:leading-[65px] relative flawor">
              Lose Weight and Keep It Off with 
                 <img src={KetoBalanced} alt="KetoBalanced" className="w-48 inline-block"/>  Effortless & Sustainable!
                
              </h3>             
              </div>              
              <p className="text-base sm:text-lg 2xl:text-2xl font-normal leading-7 sm:leading-7 2xl:leading-9 mb-5 sm:mb-9 2xl:mt-6 mt-5 relative float-start inline-block">
              Kickstart your weight loss journey with a <strong>personalized keto plan!</strong> Get <strong>easy-to-follow recipes, step-by-step meal guides, and expert tips</strong> for <strong>faster, sustainable results</strong>-without the <span className="relative flawor2">guesswork!</span></p>
              <Link to='https://usa.ketobalanced.com/'>              
              <button className="bg-btnorangeColor text-white rounded-[40px] focus:outline-none font-bold sm:w-52 h-12 2xl:mt-6 md:mt-4 mt-4 text-xl w-full">
                Start Now
              </button>
              </Link>
            </div>
            <div className="order-1 md:order-2">
              <img src={loseWeight} alt="Lose weight" className="hidden md:block" />
              <img src={loseWeight2} alt="Lose weight" className="block md:hidden" />
            </div>
          </div>
        </div>

        
      </div>
    </>
  );
};

export default LossWeight;
