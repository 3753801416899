import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import TopFooter from "../component/TopFooter";
import NewsLetter from "../component/NewsLetter";
import whycontent1 from "../../src/assets/images/whycontent1.png";
import whycontent2 from "../../src/assets/images/whycontent2.png";
import whycontent3 from "../../src/assets/images/whycontent3.png";
import Arrow3 from "../assets/images/arrow/arrow3.png";
import Arrow4 from "../assets/images/arrow/arrow4.png";
import Join from "../assets/images/join.png";
import Join1 from "../assets/images/join1.png";
const WhyKeto = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const textLimit = 150;
  const contentData = [
    {
      id: 1,
      title: "What is Keto?",
      image: whycontent1,
      bgColor: "#417421",
      fullText: `Let's simplify the keto diet in a fun, relatable way! Think of your body as a car. Normally, it runs on gasoline—aka carbs. But when you switch to keto, you upgrade to premium fuel: fat. <br/>
            The keto diet focuses on high-fat, moderate-protein, and super low-carb foods. This fuels your body in a new way, putting it into ketosis—a powerful state where your liver creates ketones from fat to energize you instead of relying on carbs.<br/>
            On keto, your plate is filled with delicious choices: meats, fish, eggs, cheese, nuts, seeds, oils, and low-carb veggies. You skip sugary treats, grains, and starchy foods like potatoes. With keto, you're not just eating differently—you're fueling transformation! ✨`,
    },
    {
      id: 2,
      title: "Why Choose the Keto Diet?",
      image: whycontent2,
      bgColor: "#A6D388",
      fullText: `Thinking about going keto? Here's why it's a game-changer! <br/>
            ✅ <b>Burn Fat for Fuel</b> - Ditch the carbs and let your body tap into fat for energy, making weight loss faster and more effective.<br/>
            ✅ <b>Curb Cravings & Stay Satisfied</b> - With healthy fats keeping you full, you'll naturally eat less and avoid those constant hunger pangs.<br/>
            ✅ <b>Boost Energy, No More Crashes</b> - Say goodbye to energy dips! Keto provides steady fuel, keeping you energized and focused all day long.<br/>
            ✅ <b>Support Blood Sugar Balance</b> - By cutting carbs, you can stabilize blood sugar levels—great for those managing diabetes or insulin resistance.<br/>
            ✅ <b>Sharpen Your Mind</b> - Many keto followers report improved mental clarity and focus, helping you stay sharp and productive.<br/><br/>
            <b>Ready to transform your health? Keto makes it easier than ever! 🚀</b>`,
    },
    {
      id: 3,
      title: "How to be in Ketosis?",
      image: whycontent3,
      bgColor: "#C6E5B3",
      fullText: `Think of your body as a furnace that usually burns firewood (carbs) for energy. But what if you switched to a cleaner, more efficient fuel like propane or oil (fat)? That's exactly what happens when you enter ketosis! <br/>
            🔥 <b>Switching Fuels:</b> By eating high-fat, moderate-protein, and low-carb foods, your body shifts from burning carbs to burning fat for fuel.<br/>
            ⚡ <b>Ketones Take Over:</b> Your liver starts producing ketones, which become your body's new energy source, keeping you fueled and focused.<br/>
            💪 <b>Burn Fat Efficiently:</b> With fat as your primary fuel, you experience steady energy, faster weight loss, and fewer energy crashes throughout the day.<br/>
            🚀 <b>Ready to switch fuels?</b> Just like adjusting a furnace, it's always best to check with a healthcare professional before making major dietary changes.`,
    },
  ];
  const [expandedItems, setExpandedItems] = useState({}); // Manage state for all items
  const toggleExpand = (id) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the specific item's state
    }));
  };
  return (
    <div className="bg-custombgColor">
      <Header />

      <div className="pt-10 md:pt-20 max-w-[840px] m-auto px-5 relative">
        <div className="hidden absolute md:left-4 lg:-left-10 md:flex justify-center items-center flex-col top-28">
          <span
            className="absolute -left-2 caveat text-lg -top-5"
            style={{ transform: "rotate(-32deg)" }}
          >
            Join our Keto <br /> Community
          </span>
          <img src={Arrow3} alt="Arrow" className="w-28" />
        </div>
        <h2 className=" mb-7 text-4xl sm:text-6xl text-left sm:text-center font-extrabold leading-10 sm:leading-[70px]">
          Why Keto Diet
        </h2>
        <p className="mb-10 font-normal text-base sm:text-2xl md:max-w-[780px] lg:max-w-[840px] m-auto text-left sm:text-center">
          The keto diet burns fat for energy, boosts mental clarity, supports
          weight loss, and enhances overall health naturally.
        </p>
        <div className="relative flex md:hidden mb-0">
          <div className="absolute -left-4">
            <img src={Join} alt="Arrow" className="w-28" />
          </div>
          <div className="absolute -right-4">
            <img src={Join1} alt="Arrow" className="w-28" />
          </div>
        </div>
        <div className="hidden absolute md:right-2 lg:-right-14 md:flex justify-center items-center flex-col top-32">
          <span className="absolute -left-2 caveat text-lg -top-12 -rotate-2 caveat">
            Intelligent way
            <br /> to keto
          </span>
          <img src={Arrow4} alt="Arrow" className="w-28" />
        </div>
      </div>

      <div className="mt-16 mx-5 md:m-20">
        {contentData.map((item) => {
          const isExpanded = expandedItems[item.id] || false; // Get the state for this specific item
          return (
            <div
              key={item.id}
              className="max-w-[960px] m-auto grid grid-cols-1 md:grid-cols-12 items-center rounded-[40px] overflow-hidden cursor-pointer"
              style={{ backgroundColor: item.bgColor, marginBottom: "20px" }}
              onClick={() => toggleExpand(item.id)}
            >
              {/* Image Section */}
              <div className="md:order-2 md:col-span-5 w-full h-full object-cover">
                <img
                  src={item.image}
                  alt={item.title}
                  style={{ objectFit: "cover", height: "100%" }}
                />
              </div>
              {/* Text Section */}
              <div className="md:col-span-7 p-6 md:p-12">
                <h3
                  className={`text-2xl md:text-3xl font-bold mb-4 ${
                    item.id === 1 ? "text-white" : "text-black"
                  }`}
                >
                  {item.title}
                </h3>
                <p
                  className={`text-lg ${
                    item.id === 1 ? "text-white" : "text-black"
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: true
                      ? item.fullText
                      : `${item.fullText.substring(0, textLimit)}...`,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <NewsLetter />
      <TopFooter />
      <Footer />
    </div>
  );
};

export default WhyKeto;
