import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import Android from "../assets/images/android.png";
import IOS from "../assets/images/ios.png";
import Pin from "../assets/images/icons/s.png";
import Fb from "../assets/images/icons/s1.png";
import Insta from "../assets/images/icons/s2.png";
import TikTok from "../assets/images/icons/s3.png";
const TopFooter = () => {
  return (
    <>
      <div className="mx-5 md:mx-16 lg:mx-24 py-12 flex justify-between">
        <div className="max-w-[50%] md:max-w-[300px] lg:max-w-[400px] w-full order-2 text-right md:text-left md:order-1">
          <div>
            <Link to="/">
              <img src={logo} alt="Logo" className="h-10 ml-auto md:ml-0" />
            </Link>
          </div>
          <p className="mt-5 text-base">
            Transform your health and lifestyle with KetoBalanced, achieving
            your goals one simple, effective step at a time.
          </p>
        </div>
        <div className="flex-grow-0 md:flex-grow md:flex-row flex-col md:ml-10 ml-0 mr-2 md:mr-0 grid md:grid-cols-4 grid-cols-1 gap-y-3 md:gap-y-0 gap-x-0 md:gap-x-8 order-1 md:order-2">
          <div>
            <h4 className="text-ftrgreenColor text-base mb-2 md:mb-3">
              COMPANY
            </h4>
            <div className="flex flex-col mb-4 md:mb-0">
              <Link
                to="/about"
                className="text-headingColor md:mb-3 font-bold hover:underline"
              >
                About
              </Link>
            </div>

            <h4 className="text-ftrgreenColor text-base mb-2 mt-6 md:mb-3">
              FOLLOW US
            </h4>
            <div className="flex flex-row mb-4 md:mb-0 gap-4 items-center flex-wrap">
              <Link to="https://in.pinterest.com/usaketobalanced/">
                <img src={Pin} alt="Pin" className="h-4" />
              </Link>
              <Link to="https://www.facebook.com/KetoBalancedDietPlans/">
                <img src={Fb} alt="Facebook" className="h-4" />
              </Link>
              <Link to="https://www.instagram.com/ketobalancedietplans/">
                <img src={Insta} alt="Insta" className="h-4" />
              </Link>
              <Link to="https://www.tiktok.com/@ketobalanced0">
                <img src={TikTok} alt="Tiktok" className="h-4" />
              </Link>
            </div>
          </div>
          <div>
            <h4 className="text-ftrgreenColor text-base mb-2 md:mb-3">HELP</h4>
            <div className="flex flex-col mb-4 md:mb-0">
              <Link
                to="/help-center"
                className="text-headingColor mb-1 md:mb-2 font-bold hover:underline"
              >
                Help Center
              </Link>
              <Link
                to="/contact"
                className="text-headingColor mb-1 md:mb-2 font-bold hover:underline"
              >
                Contact us
              </Link>
              <Link
                to="/why-keto-diet"
                className="text-headingColor mb-1 md:mb-2 font-bold hover:underline"
              >
                Why Keto Diet
              </Link>
            </div>
          </div>
          <div>
            <h4 className="text-ftrgreenColor text-base mb-2 md:mb-3">
              RESOURCE
            </h4>
            <div className="flex flex-col mb-4 md:mb-0">
              <Link
                to="https://ketobalanced.com/blog/"
                className="text-headingColor mb-0 md:mb-5 font-bold hover:underline"
              >
                Blog
              </Link>
            </div>
          </div>
          <div>
            <h4 className="text-ftrgreenColor text-base mb-2 md:mb-3">
              GET THE APP
            </h4>
            <div className="flex flex-col flex-wrap">
              <Link to="https://apps.apple.com/in/app/ketobalanced/id6473776196">
                <div className="border-headingColor border px-5 rounded-[40px] flex justify-center items-center mb-4 py-2 gap-1">
                  <img src={IOS} alt="IOS" className="" /> Ios
                </div>
              </Link>

              <Link to="https://play.google.com/store/apps/details?id=ketodietplan.carbmanager.ketoapp&hl=en_IN">
                <div className="border-headingColor border px-5 rounded-[40px] flex justify-center items-center mb-4 py-2 gap-1">
                  <img src={Android} alt="Android" className="" /> Android
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopFooter;
