import React from "react";
import whoarewe from "../assets/aboutimage/whoarewe.png";
import whyus from "../assets/aboutimage/whyus.png";
import servicemap from "../assets/aboutimage/service-map.png";
import ourmissionrightimage from "../assets/aboutimage/our-mission-right-image.png";
import titleicon1 from "../assets/aboutimage/title-icon-1.png";
import titleicon2 from "../assets/aboutimage/title-icon-2.png";
import titleicon5 from "../assets/aboutimage/title-icon-5.png";
import arroeline3 from "../assets/images/arrow/support-anytime-3.svg";
import arroeline4 from "../assets/aboutimage/arrow-line-4.png";
import titleicon3 from "../assets/aboutimage/title-icon-3.png";
import titleicon4 from "../assets/aboutimage/title-icon-4.png";
import arroeline5 from "../assets/images/arrow/support-anytime-2.svg";
import arroeline6 from "../assets/aboutimage/arrow-line-6.png";
import arroeline7 from "../assets/aboutimage/arrow-line-7.png";
import arroeline8 from "../assets/aboutimage/arrow-line-8.png";
import arroeline9 from "../assets/aboutimage/arrow-line-9.png";
import marroeline3 from "../assets/aboutimage/m-arrow-line-3.png";

const WhoWeAre = () => {
  return (
    <>
      {/* Who Are We Section */}
      <div className="w-full bg-custombgColor px-6 sm:px-12 md:px-24 pt-12 pb-32 overflow-hidden">
        <div className="container m-auto">
          <div className="text-left md:text-center">
            <h2 className="text-4xl md:text-[60px] font-extrabold inline-block text-headingColor leading-tight relative">
              <img
                src={titleicon1}
                alt="tilte-icon-1"
                className="hidden md:block absolute -left-[35px] -top-[20px]"
              />
              Who are we
              <img
                src={titleicon2}
                alt="tilte-icon-2"
                className="hidden md:block absolute -right-[60px] -bottom-[40px]"
              />
              <img
                src={titleicon5}
                alt="tilte-icon-2"
                className="block md:hidden absolute -right-[55px] -top-[30px]"
              />
            </h2>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center pt-3 md:pt-14 gap-10 md:gap-24">
            <div className="w-full md:w-1/2 relative order-last md:order-first">
              <img
                src={arroeline3}
                alt="arroeline3"
                className="absolute -top-40 left-5 hidden lg:block"
              />

              <ul className="text-base sm:text-lg 2xl:text-2xl leading-8 list-none">
                <li className="mb-10">
                  Welcome to{" "}
                  <span className="text-[#417421] font-bold">
                    KetoBalanced{" "}
                  </span>
                  Where Health Meets Flavor! Say goodbye to the carb-heavy
                  confusion and embrace the power of keto living. We're not just
                  a diet; we're a lifestyle revolution!
                </li>
                <li>
                  At{" "}
                  <span className="text-[#417421] font-bold">KetoBalanced</span>
                  , we understand the challenges of staying healthy while
                  enjoying delicious food. That's why we've crafted a range of
                  lip-smacking, keto-friendly meals, snacks, and resources to
                  make your journey to ketosis a breeze!
                </li>
              </ul>
              <img
                src={arroeline4}
                alt="arroeline3"
                className="absolute -bottom-28 right-0 md:-right-12"
              />
            </div>
            <div className="w-full md:w-1/2 mt-6 md:mt-0">
              <img
                src={marroeline3}
                alt="marroeline3"
                className="block md:hidden"
              />
              <img
                src={whoarewe}
                alt="Who are we"
                className="w-auto m-auto  justify-self-center"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Why Us Section */}
      <div className="w-full bg-custombgColor px-6 sm:px-12 md:px-24 pt-12 pb-32">
        <div className="container m-auto">
          <div className="text-left md:text-center">
            <h2 className="text-4xl md:text-[60px] font-extrabold inline-block text-headingColor leading-tight relative">
              <img
                src={titleicon3}
                alt="tilte-icon-1"
                className="hidden md:block absolute -left-[60px] -top-[30px]"
              />
              Why us?
              <img
                src={titleicon4}
                alt="tilte-icon-2"
                className="hidden md:block absolute -right-[35px] -bottom-[20px]"
              />
              <img
                src={titleicon5}
                alt="tilte-icon-2"
                className="block md:hidden absolute -right-[55px] -top-[30px]"
              />
            </h2>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center pt-3 md:pt-14 gap-10 md:gap-24">
            <div className="w-full md:w-1/2 mt-6 md:mt-0 relative">
              <img
                src={arroeline7}
                alt="arroeline1"
                className="hidden md:block absolute -top-[120px] left-0 lg:left-[20%]"
              />
              <img
                src={marroeline3}
                alt="marroeline3"
                className="block md:hidden"
              />
              <img src={whyus} alt="Who are we" className="w-auto m-auto" />
            </div>

            <div className="w-full md:w-1/2 relative">
              <img
                src={arroeline5}
                alt="arroeline3"
                className="hidden md:block absolute -top-36 -right-10 lg:right-14"
              />
              <ul className="text-base sm:text-lg 2xl:text-2xl leading-8 list-none">
                <li className="mb-10">
                  Our secret? Premium ingredients packed with healthy fats to
                  fuel your goals. Lose weight, enjoy delicious meals, and stay
                  energized all day. Join the{" "}
                  <span className="text-[#417421] font-bold">
                    {" "}
                    KetoBalanced
                  </span>{" "}
                  community for expert guidance, personalized plans, and shared
                  success.
                </li>
                <li>
                  Don't just diet—live the keto life with{" "}
                  <span className="text-[#417421] font-bold">
                    {" "}
                    KetoBalanced
                  </span>
                  ! Start your journey to better health today.
                </li>
              </ul>

              <img
                src={arroeline6}
                alt="arroeline3"
                className="absolute -bottom-28 xs:-bottom-20 md:-bottom-36 right-0 md:right-14"
              />
            </div>
          </div>
        </div>
      </div>

      {/* 480,000 Satisfied Customers Section */}
      <div className="w-full bg-custombgColor px-6 sm:px-12 md:px-24 pt-12 pb-12 md:pb-32 overflow-hidden">
        <div className=" text-left md:text-center">
          <h2 className="text-4xl md:text-[60px] font-extrabold inline-block text-headingColor leading-tight relative">
            <img
              src={titleicon1}
              alt="tilte-icon-1"
              className="hidden md:block absolute -left-[35px] -top-[20px]"
            />
            480,000 Satisfied Customers
            <img
              src={titleicon2}
              alt="tilte-icon-2"
              className="hidden md:block absolute -right-[60px] -bottom-[40px]"
            />
            <img
              src={titleicon5}
              alt="tilte-icon-2"
              className="block md:hidden absolute right-0 -top-[40px] sm:-right-[55px] sm:-top-[30px]"
            />
          </h2>
        </div>
        <div className="flex justify-center pt-10">
          <div className="w-full max-w-[900px]">
            <img
              src={servicemap}
              alt="Servicemap"
              className="md:w-full mx-auto md:mx-0  justify-self-center"
            />
          </div>
        </div>
      </div>

      {/* Our Mission Section */}
      <div className="w-full bg-custombgColor min-h-[671px] relative px-6 sm:px-12 md:px-24 pt-12 pb-4 md:pb-14">
        <img
          src={ourmissionrightimage}
          alt="ourmissionrightimage"
          className="hidden lg:block absolute right-0 top-1/2 transform -translate-y-1/2 "
        />

        <div className="container m-auto">
          <div className=" text-left md:text-center">
            <h2 className="text-4xl md:text-[60px] font-extrabold inline-block text-headingColor leading-tight relative">
              <img
                src={titleicon3}
                alt="tilte-icon-1"
                className="hidden md:block absolute -left-[60px] -top-[30px]"
              />
              Our Mission
              <img
                src={titleicon4}
                alt="tilte-icon-2"
                className="hidden md:block absolute -right-[35px] -bottom-[20px]"
              />
              <img
                src={titleicon5}
                alt="tilte-icon-2"
                className="block md:hidden absolute -right-[55px] -top-[30px]"
              />
            </h2>
          </div>
          <div className="flex flex-col md:flex-row justify-between py-0 md:py-32 gap-4 md:gap-8">
            {/* Text Content */}
            <div className="w-full flex-1 mt-6 md:mt-0 px-6 order-2 md:order-1 content-center relative">
              <img
                src={arroeline8}
                alt="Arrow"
                className="hidden md:block absolute -right-10 -top-[120px]"
              />
              <ul className=" text-base sm:text-lg 2xl:text-2xl leading-8 list-none">
                <li className="mb-10">
                  At{" "}
                  <span className="text-[#417421] font-bold">KetoBalanced</span>
                  , our mission is to empower healthier lifestyles through the
                  transformative power of the ketogenic diet. We provide
                  exceptional guidance, delicious keto-friendly foods, and
                  unwavering support to help individuals achieve their health
                  goals. By offering high-quality, accessible products, we make
                  the keto lifestyle enjoyable and sustainable.
                </li>
                <li>
                  Beyond products, we educate, inspire, and foster a supportive
                  community for lasting wellness and informed choices.
                </li>
              </ul>
              <img
                src={arroeline9}
                alt="Arrow"
                className="hidden md:block absolute -right-10 -bottom-[80px]"
              />
            </div>

            {/* Image Content */}
            <div className="w-full  mt-6 md:mt-0 flex flex-1 justify-center md:justify-end order-1 md:order-2 relative">
              <img
                src={arroeline8}
                alt="Arrow"
                className="block md:hidden absolute left-0 top-0"
              />
              <img
                src={ourmissionrightimage}
                alt="Our Mission"
                className="
              lg:hidden block"
              />
              <img
                src={arroeline9}
                alt="Arrow"
                className="block md:hidden absolute left-0 bottom-14"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoWeAre;
