import React, { useRef, useState } from "react";
import Person from "../assets/images/person.png";
const NewsLetter = () => {
  const [iserror, SetIserror] = useState(false);
  const [isdone, SetIsdone] = useState(false);
  const [message, setMessage] = useState(null);

 

  const emailInpt = useRef(null);
  const handlerSubmit = () => {
    const emailValue = emailInpt.current.value.trim();

    if (!emailValue || !emailValue.includes("@")) {
      SetIsdone(false);       
      SetIserror(true);      
      setMessage(<p className="text-base text-btntextColor">Enter a valid Email</p>)  
    } else {
      SetIserror(false);
      SetIsdone(true)
      setMessage(<p className="text-lg ">Thank you for subscribing to our newsletter! Stay tuned for updates, tips, researches and events delivered straight to your inbox.</p>)
    }
  };


  
  return (
    <>
      <div className="mx-5 md:mx-24 py-12 flex  md:flex-row flex-col justify-between border-b-2 border-btntextColor">
        <div className="max-w-96 mb-3 md:mb-0">
          <h3 className="md:text-3xl text-2xl font-extrabold">
            Join our newsletter to keep up to date with us!
          </h3>
        </div>

        <div className="max-w-[500px] w-full">
          {!isdone ? <div className="flex flex-row items-center space-x-2 md:space-x-4">
            <div className="relative max-w-[180px] sm:max-w-[350px] w-full">
              <input
                ref={emailInpt}
                type="email"
                id="username"
                placeholder="Enter your email"
                className="pl-8 pr-2 md:pr-4 py-2 border border-headingColor rounded-[40px] focus:outline-none focus:ring-2 focus:ring-slate-200 placeholder:text-base placeholder:font-normal placeholder:text-[#bebbbb] h-11 md:h-12 w-full"
              />
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                <img src={Person} alt="Person Icon" />
              </span>
            </div>
            <button
              onClick={handlerSubmit}
              className="bg-btntextColor text-white rounded-[40px] focus:outline-none text-base md:text-base lg:text-lg font-bold w-32 h-11 md:h-12 px-2"
            >
              Subscribe
            </button>
          </div> : message}
          

          {iserror && message}
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
