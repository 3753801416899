import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import NewsLetter from "../component/NewsLetter";
import TopFooter from "../component/TopFooter";
import activeavocado from "../assets/contact/active-avacado.png";
import downavocado from "../assets/contact/down-avocado.png";
import smileavacado from "../assets/contact/smile-avocado.png";
import locationicon from "../assets/contact/location.png";
import envelopeicon from "../assets/contact/envelope.png";
import phoneicon from "../assets/contact/phone.png";
import formbg from "../assets/contact/contact-bg-2.png";
import formbg1 from "../assets/contact/contact-bg.png"

const Contact = () => {
  const [avacado_img, setAvacadoImg] = useState(downavocado);
  const [bgImage, setBgImage] = useState(formbg1);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const updateBg = () => {
      setBgImage(window.innerWidth >= 768 ? formbg : formbg1);
    };

    updateBg(); 
    window.addEventListener("resize", updateBg);
    return () => window.removeEventListener("resize", updateBg);
  }, []);



  return (
    <>
      <Header />
      <div className="w-full bg-custombgColor">
        {/* search section  */}
        <br />

        <div className="py-10 md:py-16">
          {/* Main Section */}
          <div
            className="mx-6 sm:mx-12 md:mx-20 lg:mx-40 py-6 flex flex-col md:flex-row justify-center items-center bg-cover bg-no-repeat rounded-[40px] p-2 lg:p-16 "
            style={{
              backgroundImage: `url(${bgImage}`,
            }}
          >
          

          <div className="block md:hidden text-center pt-16 pb-8 px-5">
                  <h1 className="text-3xl sm:text-4xl md:text-[40px] font-bold text-white mb-4">
                     Contact Us
                  </h1>                  
                  <p className="text-base sm:text-lg 2xl:text-2xl mb-6 text-white">
                    Have questions or need assistance? Our team is just a message away. Let us know how we can support you.
                  </p>
            </div>

            <div
              className="text-center lg:text-left w-full md:w-full lg:max-w-[400px] 2xl:max-w-[500px] order-2 md:order-1"
              onMouseEnter={() => {
                setAvacadoImg(smileavacado);
              }}
              onMouseLeave={() => {
                setAvacadoImg(downavocado);
              }}
            >
              <div className="bg-custombgColor items-center rounded-3xl p-2 lg:p-10 shadow-2xl -mt-[180px] sm:-mt-[150px] md:mt-0 lg:mt-0">
                {/* Form Content */}
                <div className="max-w-lg">
                  {/* Heading */}
                  <h1 className="text-center md:text-left text-3xl sm:text-4xl md:text-[40px] font-bold text-gray-800 mb-4">
                    Get in Touch
                  </h1>
                  {/* Paragraph */}
                  <p className="text-center md:text-left text-base sm:text-lg 2xl:text-2xl mb-6 text-gray-700">
                    Feel free to reach out to us any time!
                  </p>
                  {/* Form content */}
                  <div className="flex gap-2 mb-2 items-center text-left text-base sm:text-lg 2xl:text-2xl text-gray-700">

                    <img src={locationicon} width={40} height={40} />
                    312 West 2nd Street #A356 Casper, WY 82601, US
                  </div>
                  <div className="flex gap-2 mb-2 items-center text-base sm:text-lg 2xl:text-2xl text-gray-700">
                    <img src={envelopeicon} width={40} height={40} />
                    support@ketobalanced.com
                  </div>
                  <div className="flex gap-2 mb-2 items-center text-base sm:text-lg 2xl:text-2xl text-gray-700">
                    <img src={phoneicon} width={40} height={40} />
                    Wiselivingincorporation Media Limited
                  </div>
                </div>
              </div>
            </div>

            {/* Avocado Illustration */}
            <div className="w-full sm:w-1/2 order-1 md:order-2">
              <img
                src={avacado_img}
                alt="Happy Avocado"
                className="max-w-xs lg:max-w-sm w-full md:w-1/2 mx-auto"
              />
            </div>
          </div>
        </div>

       
     
      <NewsLetter />
       <TopFooter />
      <Footer />
      </div>
    </>
  );
};

export default Contact;